export const supplierConstants = {
  // REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  // REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  // REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  // LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  // LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  // LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  GETALL_REQUEST: "SUPPLIERS_GETALL_REQUEST",
  GETALL_SUCCESS: "SUPPLIERS_GETALL_SUCCESS",
  GETALL_FAILURE: "SUPPLIERS_GETALL_FAILURE",

  GETBYID_REQUEST: "GETBYID_REQUEST",
  GETBYID_SUCCESS: "GETBYID_SUCCESS",
  GETBYID_FAILURE: "GETBYID_FAILURE",

  GET_SUPPLIERS_REQUEST: "SUPPLIERS_GET_SUPPLIERS_REQUEST",
  GET_SUPPLIERS_SUCCESS: "SUPPLIERS_GET_SUPPLIERS_SUCCESS",
  GET_SUPPLIERS_FAILURE: "SUPPLIERS_GET_SUPPLIERS_FAILURE",

  CREATE_REQUEST: "SUPPLIERS_CREATE_REQUEST",
  CREATE_SUCCESS: "SUPPLIERS_CREATE_SUCCESS",
  CREATE_FAILURE: "SUPPLIERS_CREATE_FAILURE",

  UPDATE_REQUEST: "SUPPLIERS_UPDATE_REQUEST",
  UPDATE_SUCCESS: "SUPPLIERS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "SUPPLIERS_UPDATE_FAILURE",

  DELETE_REQUEST: "SUPPLIERS_DELETE_REQUEST",
  DELETE_SUCCESS: "SUPPLIERS_DELETE_SUCCESS",
  DELETE_FAILURE: "SUPPLIERS_DELETE_FAILURE",

  UPLOAD_REQUEST: "PDF_UPLOAD_REQUEST",
  UPLOAD_SUCCESS: "PDF_UPLOAD_SUCCESS",
  UPLOAD_FAILURE: "PDF_UPLOAD_FAILURE",

  GET_UPLOAD_FILE_REQUEST: "SUPPLIERS_GET_LIST_FILES_REQUEST",
  GET_UPLOAD_FILE_SUCCESS: "SUPPLIERS_GET_LIST_FILES_SUCCESS",
  GET_UPLOAD_FILE_FAILURE: "SUPPLIERS_GET_LIST_FILES_FAILURE",

  GET_LIST_SUPPLIERS_REQUEST: "GET_LIST_SUPPLIERS_REQUEST",
  GET_LIST_SUPPLIERS_SUCCESS: "GET_LIST_SUPPLIERS_SUCCESS",
  GET_LIST_SUPPLIERS_SUCCESS: "GET_LIST_SUPPLIERS_SUCCESS",
};
