import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { experimentalStyled, Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions/user.actions";
import { LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_USER } from "../../../constants";

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const [isRightNavOpen, setRightNavOpen] = useState(false);

  const [state, setState] = useState({
    openSnackbar: false,
  });
  const { openSnackbar } = state;

  const handleSnackbarOpen = () => {
    setState({ ...state, openSnackbar: true });
  };

  const handleSnackbarClose = () => {
    setState({ ...state, openSnackbar: false });
  };

  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const alert = useSelector((state) => state.alert);
  const error = useSelector((state) => state.error);

  useEffect(() => {
    dispatch(userActions.getUserLoggedIn());
  }, []);

  const myInterval = setInterval(() => {
    if (
      localStorage.getItem(LOCAL_STORAGE_TOKEN) == null ||
      localStorage.getItem(LOCAL_STORAGE_USER) == null
    ) {
      window.location.href = "/login";
    }
  }, 3000);

  useEffect(() => {
    if (
      userLoggedIn &&
      userLoggedIn.error &&
      location.pathname != "/reissue-password"
    ) {
      localStorage.removeItem(LOCAL_STORAGE_USER);
      localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    }
    error;
  }, [userLoggedIn]);

  useEffect(() => {
    if (alert.message) {
      handleSnackbarOpen();
    }
  }, [alert]);
  return (
    <DashboardLayoutRoot>
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
            {alert.message && (
              <Snackbar
                autoHideDuration={10000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={openSnackbar}
                onClose={handleSnackbarClose}
                key="personnelpage-snackbar"
              >
                <Alert onClose={handleSnackbarClose} severity={alert.type}>
                  {alert.message}
                </Alert>
              </Snackbar>
            )}
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
