import { Dialog } from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import React, { useRef, useImperativeHandle } from "react";
import PageHeader from "../../../components/PageHeader";
import MenuBar from "../../../components/MenuBar";
import { useForm } from "react-hook-form";
import { LOCAL_STORAGE_USER } from "../../../../constants";
import { notificationActions } from "../../../actions";
import DialogCompletedCreateNoti from "../../../components/DialogCompletedCreateNoti";
import DialogConfirmCreateNoti from "../../../components/DialogConfirmCreateNoti";
import { useDispatch } from "react-redux";
import { getLocalStorage } from "../../../utils/helper/localStorage";
import Moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

export default function DialogNotiRegistration({ open, onClose }) {
  const dispatch = useDispatch();
  const userLogin = getLocalStorage(LOCAL_STORAGE_USER);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [content, setContent] = React.useState("");
  const [link, setLink] = React.useState("");
  const navigate = useNavigate();
  const formRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    trigger,
  } = useForm({ mode: "onBlur" });

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose(false);
    reset();
  };

  const handleClickAway = () => {
    $(function () {
      if ($("div:contains('お知らせを作成しますか？')").length == 0) {
        handleClose();
      }
    });
  };

  useImperativeHandle(formRef, () => ({
    submitForm() {
      handleSubmit(handleFormSubmit)();
      if (!isValid) {
        setConfirmDialogOpen(false);
      }
    },
  }));

  const handleFormSubmit = async (data) => {
    data.admin_id = userLogin?.id;
    // data.created_at = Moment(new Date()).format("YYYY/MM/DD");
    // data.updated_at = Moment(new Date()).format("YYYY/MM/DD");
    await dispatch(notificationActions.create(data));
    setSuccessDialogOpen(true);
    onClose();
    setConfirmDialogOpen(false);
    reset();
  };

  return (
    <div className="w-fit h-fit">
      <Dialog open={open} onClose={handleClose} className="z-40">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="justify-center items-center flex fixed inset-0 z-40 outline-none focus:outline-none">
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className="w-[870px] h-fit bg-white rounded-[10px] flex flex-col pb-[10px] overflow-y-auto pr-[20px] pt-[25px] pl-[25px]">
                <span className="text-xl font-bold">お知らせ 新規作成</span>
                <div className="w-full flex mt-[50px]">
                  <label className="w-[145px] mt-[-7px]">
                    内容 ※30文字まで
                  </label>
                  <input
                    {...register("notification_contents", {
                      required: "お知らせの内容を入力してください",
                      maxLength: {
                        value: 30,
                        message: "お知らせの内容を30文字以下入力してください",
                      },
                      onChange: (e) => setContent(e.target.value),
                    })}
                    type="text"
                    className="w-[655px] h-[65px] rounded-lg border-[#CCCCCC] bg-white border-[1px] pl-[10px] pr-[10px]"
                  />
                </div>
                {errors.notification_contents && (
                  <div className="ml-[145px] w-fit text-red-500 mb-[20px] h-px">
                    <span>{errors.notification_contents.message}</span>
                  </div>
                )}
                <div className="w-[97%] h-px bg-zinc-300 mt-[10px]"></div>
                <div className="w-full flex mt-[10px]">
                  <label className="w-[145px]">遷移先リンク</label>
                  <input
                    {...register("link", {
                      required: "遷移先リンクを入力してください",
                      onChange: (e) => setLink(e.target.value),
                    })}
                    type="text"
                    className="w-[655px] h-[30px] rounded-lg border-[#CCCCCC] bg-white border-[1px] pl-[10px] pr-[10px]"
                  />
                </div>
                {errors.link && (
                  <div className="ml-[145px] w-fit text-red-500 mb-[20px] h-px">
                    <span>{errors.link.message}</span>
                  </div>
                )}
                <div className="w-[97%] h-px bg-zinc-300 mt-[10px]"></div>
                <div className="w-full flex mt-[10px]">
                  <label className="w-[145px]">公開設定</label>
                  <input
                    {...register("is_public")}
                    checked
                    className=""
                    type="radio"
                    name="is_public"
                    value="1"
                    id="public"
                  />
                  <label htmlFor="public" className="w-[145px] ml-[5px]">
                    公開する
                  </label>
                  <input
                    {...register("is_public")}
                    className=""
                    type="radio"
                    name="is_public"
                    value="0"
                    id="unpublic"
                  />
                  <label htmlFor="unpublic" className="w-[145px] ml-[5px]">
                    非公開にする
                  </label>
                </div>
                <div className="w-[97%] h-px bg-zinc-300 mt-[10px]"></div>
                <div className="flex justify-center mt-[20px] mb-[20px]">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onClose();
                      reset();
                    }}
                    className="w-[260px] h-[39px] border-[#CCCCCC] bg-white border-[1px] rounded-3xl font-bold text-xl text-[#CCCCCC] hover:bg-gray-100"
                  >
                    キャンセル
                  </button>
                  <button
                    // type="submit"
                    onClick={async (e) => {
                      e.preventDefault();
                      const result = await trigger(
                        ["link", "notification_contents"],
                        { shouldFocus: true }
                      );
                      if (result) {
                        setConfirmDialogOpen(true);
                      }
                    }}
                    className={`${
                      !link || !content ? "bg-[#748bbe]" : "bg-blue-900"
                    } w-[260px] h-[39px] border-[#CCCCCC] text-white border-[1px] rounded-3xl font-bold text-xl ml-[30px] hover:bg-violet-600`}
                  >
                    お知らせを作成する
                  </button>
                </div>
              </div>
            </ClickAwayListener>
          </div>
        </form>
      </Dialog>
      <DialogConfirmCreateNoti
        open={confirmDialogOpen}
        // message="お知らせを作成しますか？ \n※公開設定を「公開」にしている場合、\nお知らせはユーザに公開されます"
        onConfirm={() => formRef.current.submitForm()}
        onClose={() => {
          setConfirmDialogOpen(false);
        }}
      />
      <DialogCompletedCreateNoti
        open={successDialogOpen}
        message="お知らせを作成しました"
        onClose={() => {
          setSuccessDialogOpen(false);
          navigate("/notifications");
        }}
      />
    </div>
  );
}
