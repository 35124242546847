import React from "react";

export default function UserListTab({ onOpenApproveTab, onOpenUnApproveTab }) {
  const [isTabApprove, setIsTabApprove] = React.useState(true);
  const [isTabUnApprove, setIsTabUnApprove] = React.useState(false);

  const handleOpenApproveTab = () => {
    setIsTabApprove(true);
    setIsTabUnApprove(false);
  };

  const handleUnApproveTab = () => {
    setIsTabApprove(false);
    setIsTabUnApprove(true);
  };

  return (
    <div className="mt-[25px] text-sm font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px">
        <li
          onClick={() => {
            onOpenApproveTab();
            handleOpenApproveTab();
          }}
          className={`${
            isTabApprove ? "bg-zinc-100" : "bg-[#b9b9b9]"
          } me-1 w-[140px] h-[30px] px-[22px] py-[9px]  rounded-tl rounded-tr justify-center items-center gap-2.5 inline-flex cursor-pointer`}
        >
          <a
            href="#"
            className="text-center text-neutral-500 text-xs font-bold "
          >
            審査済
          </a>
        </li>
        <li
          onClick={() => {
            onOpenUnApproveTab();
            handleUnApproveTab();
          }}
          className={`${
            isTabUnApprove ? "bg-zinc-100" : "bg-[#b9b9b9]"
          } me-2 w-[140px] h-[30px] px-[22px] py-[9px] rounded-tl rounded-tr justify-center items-center gap-2.5 inline-flex cursor-pointer`}
        >
          <a
            href="#"
            className="text-center text-neutral-500 text-xs font-bold "
          >
            未審査
          </a>
        </li>
      </ul>
    </div>
  );
}
