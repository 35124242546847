import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/logo_tohohozai.png";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../actions/user.actions.js";
import weblogo from "../../../assets/weblogo.png";
import { LinearProgress } from "@mui/material";

export default function index() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const issuePassword = useSelector((state) => state.user.reissuePassword);

  // メール入力欄が空白でない場合、送信ボタンが活性
  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    if (error && isValidEmail(inputEmail)) setError("");
    else if (!isValidEmail(inputEmail))
      setError("メールアドレスが無効です");
    setActive(isValidEmail(inputEmail));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValidEmail(email)) {
      setError("入力した情報に誤りがあります");
      return;
    }
    // Sending email API
    dispatch(userActions.reissuePassword(email));
  };

  React.useEffect(() => {
    if (issuePassword.error) {
      setError(issuePassword.error.message);
    } else {
      setError("");
    }
  }, [issuePassword]);

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <div className="flex w-screen h-screen bg-[#1A2847] items-center flex-col justify-center font-montserrat">
      <img className="w-[251px] h-[123px]" src={weblogo} />
      <div className="w-fit h-fit flex flex-col items-center justify-center mt-[10px]">
        <div className="mt-[10px] mb-[20px] text-center text-white text-lg font-medium leading-[30px]">
          KENZAI LIFE
        </div>
      </div>
      <div className="mt-[30px] flex flex-col items-start">
        <label
          htmlFor="email"
          className="block text-sm font-normal text-white mb-1 "
        >
          PW再発行用メールアドレス
        </label>
        <div className="relative">
          <input
            id="email"
            type="email"
            placeholder="メールアドレス"
            className="p-2 w-[315px] h-[30px] rounded-[5px] border border-white bg-transparent text-white placeholder-white"
            value={email}
            onChange={handleEmailChange}
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = "メールアドレス")}
            autoComplete="off"
          />
          {error && (
            <div className="text-red-500 text-xs mt-1 absolute bottom-[-20px] font-normal whitespace-nowrap">
              {error}
            </div>
          )}
          {issuePassword.item && (
            <div className="text-green-500 text-xs mt-1 absolute bottom-[-20px] font-normal whitespace-nowrap">
              メール送信が完了しました。
            </div>
          )}
        </div>
      </div>

      <div className="w-[315px] text-white text-[10px] font-normal mt-[30px]">
        ご登録されているメールアドレス宛に再発行した仮PWを送信いたします。
        <br />
        メールアドレスをお忘れの場合は、お手数ですが再度アカウントの作成をお願い致します。
      </div>

      {issuePassword.loading && (
        <LinearProgress className="mt-[20px] mb-[20px] w-[100px] h-[2px] text-center" />
      )}

      <button
        className={`${
          !active ? "bg-[#95ABD999]" : "bg-[#183F93]"
        } w-[261px] h-10 text-white text-opacity-60 rounded-3xl flex items-center justify-center mt-[50px]`}
        disabled={!active}
        onClick={handleSubmit}
      >
        <span className="text-white text-[15px] font-medium">送信する</span>
      </button>

      <button
        className="w-[261px] h-10 text-black rounded-3xl bg-white flex items-center justify-center mt-[30px] "
        onClick={handleBack}
      >
        <span className="text-opacity-60 text-[15px] font-medium">
          トップへ戻る
        </span>
      </button>
    </div>
  );
}
