import * as React from "react";
import useNavigatorOnLine from "../hooks/useNetworkStatus";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function NetworkStatusIndicator() {
  const isOnline = useNavigatorOnLine();
  const firstUpdate = React.useRef(true);

  React.useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    isOnline
      ? console.log("")
      : toast.error(
          "通信が失敗しました。すべてのコンテンツを表示するにはは、再接続してください。!",
          {
            position: "top-right",
          }
        );
  }, [isOnline]);

  return (
    <div>
      <ToastContainer />
    </div>
  );
}
