import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled, Snackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const MainLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const MainLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const MainLayout = () => {
  const [state, setState] = useState({
    openSnackbar: false
  });
  const { openSnackbar } = state;

  const handleSnackbarOpen = () => {
    setState({ ...state, openSnackbar: true });
  };

  const handleSnackbarClose = () => {
    setState({ ...state, openSnackbar: false });
  };

  const alert = useSelector((state) => state.alert);

  useEffect(() => {
    if (alert.message) {
      handleSnackbarOpen();
    }
  }, [alert]);
  return (
    <MainLayoutRoot>
      <MainLayoutWrapper>
        <MainLayoutContainer>
          <MainLayoutContent>
            <Outlet />
            {alert.message && (
              <Snackbar
                autoHideDuration={10000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnackbar}
                onClose={handleSnackbarClose}
                key="personnelpage-snackbar"
              >
                <Alert onClose={handleSnackbarClose} severity={alert.type}>
                  {alert.message}
                </Alert>
              </Snackbar>
            )}
          </MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
    </MainLayoutRoot>
  );
};

export default MainLayout;
