import React from "react";
import maskgroup from "../assets/maskgroup.png";
import list from "../assets/list.png";
import settings from "../assets/settings.svg";
import logout from "../assets/logout.png";
import bellicon from "../assets/bellicon.png";
import { Link } from "react-router-dom";
import LogoutConfirmDialog from "./LogoutConfirmDialog.jsx";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function MenuBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [menu, setMenu] = React.useState("userlist");
  const [modalLogoutOpen, setModalLogoutOpen] = React.useState(false);

  React.useEffect(() => {
    setMenu(location.pathname);
  }, [location]);

  const handleLogout = () => {
    setModalLogoutOpen(true);
    setMenu("logout");
  };

  return (
    <div className="relative flex flex-col bg-zinc-100 2xl:w-2/12 xl:w-2/12 lg:w-2/12">
      <div className="w-full left-[3px] flex bg-zinc-100"></div>
      <div
        className={`${
          menu == "/userlist" ? "bg-white" : ""
        } pl-[23px] pt-[10px] pb-[10px] w-full left-[3px] flex  mt-[29px] flex flex-row cursor-pointer`}
      >
        <img className="w-[27px] h-[27px] ml-[1px] " src={maskgroup} />
        <Link to="/userlist" className="w-full ml-[13px] mt-[1px]">
          ユーザー管理
        </Link>
      </div>
      <div
        className={`${
          menu == "/supplier-management-map" ||
          menu == "/batchregistration" ||
          menu == "/supplier-management-list" ||
          menu == "/supplier-management-files"
            ? "bg-white"
            : ""
        } pl-[23px] pt-[10px] pb-[10px] w-full h-[50px] mt-[10px] ml-[3px] flex flex-row items-center cursor-pointer`}
      >
        <img className="w-[22px] h-[14px] flex item-center" src={list} />
        <Link
          to="/supplier-management-map"
          className="w-full ml-[17px] mt-[-2px]"
        >
          仕入先管理
        </Link>
      </div>
      <div
        className={`${
          menu == "/settings" ? "bg-white" : ""
        } pl-[25px] pt-[10px] pb-[10px] w-full h-[50px] mt-[10px] flex flex-row items-center cursor-pointer`}
      >
        <img className="w-[25px] h-[27px]" src={settings} />
        <Link to="/settings" className="w-full ml-[18px]">
          設定
        </Link>
      </div>

      <div
        className={`${
          menu == "/notifications" ? "bg-white" : ""
        } pl-[21px] pt-[10px] pb-[10px] w-full h-[50px] mt-[10px] flex flex-row items-center cursor-pointer`}
      >
        <img className="w-[33px] h-[33px]" src={bellicon} />
        <Link to="/notifications" className="w-full ml-[12px] mt-[2px] ">
          お知らせ
        </Link>
      </div>
      <div
        className={`${
          menu == "logout" ? "bg-white" : ""
        } pl-[25px] mt-[10px] pt-[10px] pb-[10px] w-full h-[50px] flex flex-row items-center cursor-pointer`}
        onClick={() => handleLogout()}
      >
        <img className="w-[27px] h-[27px] ml-[1px]" src={logout} />
        <button className="w-auto h-auto mt-[-2px] ml-[12px]">
          ログアウト
        </button>
      </div>
      <LogoutConfirmDialog
        open={modalLogoutOpen}
        onClose={() => setModalLogoutOpen(false)}
      />
    </div>
  );
}
