import React from "react";
import weblogo from "../assets/weblogo.png";

export default function PageHeader() {
  return (
    <div className="flex lg:w-screen xl:w-screen md:h-[10%] 2xl:h-[10%] xl:h-[10%] 2xl:w-screen md:w-screen">
      <div className="flex items-center xl:w-screen md:h-[10vh] 2xl:h-[10vh] xl:h-[10vh] left-0 top-0 bg-zinc-800 2xl:w-screen lg:w-screen md:w-screen">
        <div className="ml-[30px] text-center text-white text-xl font-bold ">
          <img className="w-[110px] h-[54px]" src={weblogo} />
        </div>
      </div>
    </div>
  );
}
