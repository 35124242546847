import React from "react";
import PageHeader from "../../../components/PageHeader";
import MenuBar from "../../../components/MenuBar";
import Moment from "moment";
import { useForm } from "react-hook-form";
import { adminActions } from "../../../actions";
import { userActions } from "../../../actions";
import { getLocalStorage } from "../../../utils/helper/localStorage";
import { LOCAL_STORAGE_USER } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { supplier } from "../../../reducers/supplier.reducer";
import { Tooltip } from "@mui/material";
import person from "../../../assets/person.png";
import hide from "../../../assets/hide.png";
import show from "../../../assets/show.png";
import $ from "jquery";
import { toast, ToastContainer } from "react-toastify";
import { adminConstants } from "../../../constants";

export default function Settings() {
  const [isEdit, setIsEdit] = React.useState(false);
  const userLogin = getLocalStorage(LOCAL_STORAGE_USER);
  const admin = useSelector((state) => state.admin.get);
  const [errorMsg, setErrorMsg] = React.useState("");
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState(0);
  const [month, setMonth] = React.useState(0);
  const [year, setYear] = React.useState(0);
  const [isHide, setIsHide] = React.useState(true);
  const logs = useSelector((state) => state.user.getLogs);
  const update = useSelector((state) => state.admin.update);

  const fields = ["name", "email", "updated_at", "created_at"];

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    reset,
    setValue,
  } = useForm();

  const getAdmin = async () => {
    if (userLogin?.id) {
      dispatch(adminActions.get(userLogin?.id));
    }
  };

  React.useEffect(() => {
    getAdmin();
  }, []);

  React.useEffect(() => {
    if (admin.item) {
      fields.forEach((field) => {
        setValue(field, admin.item?.[field]);
      });
    }
  }, [admin]);

  React.useEffect(() => {
    if (update.item) {
      dispatch({ type: adminConstants.UPDATE_SUCCESS, payload: null });
      setIsEdit(!isEdit);
      setErrorMsg("");
      reset();
      getAdmin();
    } else if (update.error) {
      if (update.error.email) {
        const formError = {
          type: "server",
          message: update.error.email.message,
        };
        setError("email", formError);
      }
    }
  }, [update]);

  React.useEffect(() => {
    if (logs?.error) {
      toast.error(
        "選択した年、月にデータがありません。他の年、月に変更してください。",
        {
          position: "top-right",
        }
      );
    }
  }, [logs]);

  const handleEdit = async (data) => {
    if (isEdit) {
      if (
        data.password_hash != "" &&
        data.confirm_password != data.password_hash
      ) {
        setErrorMsg("パスワードとパスワード（確認）が一致しません");
        return;
      }
      data.password_hash =
        data.password_hash != ""
          ? data.password_hash
          : admin.item.password_hash;
      data.id = admin.item.id;
      await dispatch(adminActions.update(data));
    }
  };

  const handleChangeMonth = (e) => {
    setMonth(e.target.value);
  };

  const handleChangeYear = (e) => {
    setYear(e.target.value);
  };

  const handleDownload = () => {
    dispatch(userActions.getLogs(month, year));
  };

  const handleResetMonthYear = () => {
    setMonth(0);
    setYear(0);
  };

  $(function () {
    $("select").on("change", function () {
      if ($(this).val() == "") $(this).css({ color: "#aaa" });
      else $(this).css({ color: "#000" });
    });
  });

  return (
    <div>
      <PageHeader />
      <div className="mt-[10px] flex flex-row h-screen 2xl:w-screen xl:w-screen lg:w-screen">
        <MenuBar />
        <div className="pl-[2%] 2xl:w-[80%] xl:w-[70%] lg:w-[70%]">
          <div className="text-black text-xl font-medium ">設定</div>
          <div className="2xl:w-[100%] h-px bg-zinc-300 mt-[8px] xl:w-[100%] lg:w-[100%]" />
          <div className="mt-[26px] flex flex-row 2xl:w-[95%] xl:w-[95%] lg:w-[95%]">
            <div className="w-[223px] h-[611px] flex flex-col bg-white rounded-[10px] shadow border border-zinc-300">
              <Tooltip title={admin?.item?.name} arrow placement="bottom">
                <div className="text-neutral-700 w-[70px] truncate text-base font-normal mt-[44px] ml-[60px]">
                  {admin?.item?.name}
                </div>
              </Tooltip>
              <div className="text-neutral-700 text-base font-normal mt-[5px] ml-[60px]">
                ID：{"100" + admin?.item?.id}
              </div>
              <div className="mt-[11px] ml-[8px] w-[194px] h-px bg-zinc-300" />
              <div
                onClick={() => setTab(0)}
                className={`${
                  tab == 0 ? "bg-zinc-100" : ""
                } flex items-center w-[221px] h-[46px] pl-[7px] mt-[16px] text-neutral-700 text-sm font-normal cursor-pointer`}
              >
                登録情報
              </div>
              <div
                onClick={() => {
                  setTab(1);
                  setIsEdit(false);
                  handleResetMonthYear();
                }}
                className={`${
                  tab == 1 ? "bg-zinc-100" : ""
                } flex items-center w-[221px] h-[46px] pl-[7px] mt-[5px] text-neutral-700 text-sm font-normal cursor-pointer`}
              >
                アクセスログ
              </div>
              {/* <div className="mt-[10px] ml-[7px] text-neutral-700 text-sm font-normal ">
                作成したユーザー（20）
              </div>
              <div className="mt-[23px] ml-[8px] text-neutral-700 text-sm font-normal ">
                作成した仕入先（10）
              </div> */}
            </div>

            {tab == 0 && (
              <form className="w-full pr-[2%]">
                <div className=" h-[611px] bg-white rounded-[10px] shadow border-r border-t border-b border-zinc-300 pf-[5%]">
                  <div className="flex flex-row w-full h-[18px] justify-end mt-[23px] pr-[17px] space-x-[17px]">
                    {isEdit ? (
                      <button
                        type="button"
                        onClick={handleSubmit(handleEdit)}
                        className="w-[61px] h-[18px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold "
                      >
                        保存
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsEdit(true);
                        }}
                        className="w-[10%] h-[20px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold "
                      >
                        編集
                      </button>
                    )}
                  </div>
                  <div className="w-2/4 flex justify-center">
                    <div className="w-[109px] h-[109px] bg-zinc-300 rounded-full flex items-center justify-center">
                      <img src={person} className="w-[49px] h-[49px]"></img>
                    </div>
                  </div>

                  <div className="flex flex-row mt-[80px] justify-between pl-[3%] pr-[3%]">
                    <div className="flex flex-col w-[48%]">
                      <div className="flex flex-row">
                        <div className="pt-3 text-neutral-700 text-sm font-normal w-[29%]">
                          社員名
                        </div>
                        {isEdit ? (
                          <input
                            readOnly={!isEdit}
                            {...register("name", {
                              required: "ユーザー名を入力してください",
                            })}
                            className="p-2 h-[35px] w-[71%] truncate border border-stone-300 bg-white rounded-[10px]"
                            placeholder="ユーザー名"
                          />
                        ) : (
                          <Tooltip title={admin?.item?.name} arrow>
                            <div className="p-2 h-[35px] w-[71%] truncate bg-white rounded-[10px]">
                              {admin?.item?.name}
                            </div>
                          </Tooltip>
                        )}
                      </div>
                      {errors.name && (
                        <div className="text-red-500 mt-2">
                          <span className="whitespace-nowrap">
                            {errors.name.message}
                          </span>
                        </div>
                      )}
                      <div className="mt-2 w-full h-[0px] border border-zinc-300"></div>
                    </div>
                    <div className="mt-3 flex flex-col w-[48%]">
                      <div className="flex flex-row items-center">
                        <div className="text-neutral-700 text-sm font-normal w-[29%]">
                          ユーザーID
                        </div>
                        <div className="text-black text-opacity-60 text-base font-normal ">
                          {"100" + admin?.item?.id}
                        </div>
                      </div>
                      <div className="mt-2 w-full h-[0px] border border-zinc-300"></div>
                    </div>
                  </div>
                  <div className="flex flex-col mt-[36px] w-[48%] pl-[3%]">
                    <div className="flex flex-row">
                      <div className="pt-2 text-neutral-700 text-sm font-normal w-[29%]">
                        メールアドレス
                      </div>
                      {isEdit ? (
                        <input
                          readOnly={!isEdit}
                          {...register("email", {
                            required: "メールアドレスを入力してください",
                          })}
                          className="p-2 h-[35px] border border-stone-300 bg-white rounded-[10px] w-[71%]"
                          placeholder="Email"
                        />
                      ) : (
                        <Tooltip
                          title={admin?.item?.email}
                          arrow
                          placement="bottom"
                        >
                          <div className="p-2 h-[35px] bg-white rounded-[10px] truncate w-[71%]">
                            {admin?.item?.email}
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    {errors.email && (
                      <div className="text-red-500 mt-2">
                        <span className="whitespace-nowrap">
                          {errors.email.message}
                        </span>
                      </div>
                    )}
                    <div className="mt-2 w-full h-[0px] border border-zinc-300"></div>
                  </div>
                  <div className="flex flex-col mt-[36px] w-[48%] pl-[3%]">
                    <div className="flex flex-row">
                      <div className="pt-2 text-neutral-700 text-sm font-normal w-[29%]">
                        パスワード
                      </div>
                      {isEdit ? (
                        <>
                          <input
                            // type={isHide ? "password" : "text"}
                            type="password"
                            readOnly={!isEdit}
                            {...register("password_hash", {
                              pattern: {
                                value:
                                  /^(?=.*?[0-9])(?=.*?[A-Za-z]).{8,}$/,
                                message:
                                  "半角英数字と記号を組み合わせた8文字以上のパスワードを入力してください",
                              },
                            })}
                            className="p-2 h-[35px] border border-stone-300 bg-white rounded-[10px] w-[71%]"
                            placeholder="パスワード"
                          />

                          {/* <img
                            src={isHide ? hide : show}
                            onClick={() => setIsHide(!isHide)}
                            className="mt-1 ml-8 w-8 h-7"
                            title="password icons"
                          /> */}
                        </>
                      ) : (
                        <div className="p-2 h-[35px] bg-white rounded-[10px] w-[71%]">
                          ************************
                        </div>
                      )}
                    </div>
                    {errors.password_hash && (
                      <div className="text-red-500 mt-2">
                        <span className="whitespace-nowrap">
                          {errors.password_hash.message}
                        </span>
                      </div>
                    )}
                    <div className="mt-2 w-full h-[0px] border border-zinc-300"></div>
                  </div>
                  {isEdit && (
                    <div className="flex flex-col mt-[36px] w-[48%] pl-[3%]">
                      <div className="flex flex-row">
                        <div className="pt-2 text-neutral-700 text-sm font-normal w-[29%]">
                          パスワード（確認）
                        </div>
                        <input
                          type={isHide ? "password" : "text"}
                          readOnly={!isEdit}
                          {...register("confirm_password")}
                          className="p-2 h-[35px] border border-stone-300 bg-white rounded-[10px] w-[71%]"
                          placeholder="パスワード（確認）"
                        />
                      </div>
                      <div className="mt-2 w-full h-[0px] border border-zinc-300"></div>
                      {errorMsg && (
                        <div className="text-red-500 mt-2">{errorMsg}</div>
                      )}
                    </div>
                  )}
                </div>
              </form>
            )}
            {tab == 1 && (
              <>
                <ToastContainer />
                <div className="w-full pr-[2%]">
                  <div className="flex flex-col h-[611px] bg-white rounded-[10px] shadow border-r border-t border-b border-zinc-300 pf-[5%] items-center pt-[50px]">
                    <div className="w-[20%] flex justify-around">
                      <div className="w-[98px] h-[28px] flex justify-center">
                        <select
                          id="year"
                          required
                          onChange={(e) => handleChangeYear(e)}
                          className=" w-full h-7 text-left bg-white rounded-[10px] border border-stone-300 focus:outline-none invalid:text-gray-400 text-sm pl-[10px]"
                        >
                          <option
                            value=""
                            className="text-zinc-200"
                            disabled
                            selected
                          >
                            年
                          </option>
                          <option value="2020">2020</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2024">2025</option>
                          <option value="2024">2026</option>
                          <option value="2024">2027</option>
                          <option value="2024">2028</option>
                          <option value="2024">2029</option>
                          <option value="2024">2030</option>
                          <option value="2024">2031</option>
                          <option value="2024">2032</option>
                          <option value="2024">2033</option>
                          <option value="2024">2034</option>
                          <option value="2024">2035</option>
                          <option value="2024">2036</option>
                          <option value="2024">2037</option>
                          <option value="2024">2038</option>
                          <option value="2024">2039</option>
                          <option value="2024">2040</option>
                        </select>
                      </div>
                      <div className="w-[40%] flex justify-center">
                        <select
                          required
                          id="month"
                          onChange={(e) => handleChangeMonth(e)}
                          className="w-[86px] h-[28px] text-left bg-white rounded-[10px] border border-stone-300 focus:outline-none invalid:text-gray-400 text-sm pl-[10px]"
                        >
                          <option
                            value=""
                            className="text-zinc-200"
                            disabled
                            selected
                          >
                            月
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                    </div>
                    <button
                      onClick={() => handleDownload()}
                      type="button"
                      disabled={month == 0 || year == 0}
                      className="mt-[30px] w-[167px] h-[27px] bg-white rounded-[50px] border border-blue-900 text-center text-blue-900 text-xs font-bold cursor-pointer active:bg-gray-300"
                    >
                      CSV アクセスログ
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
