import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuBar from "../../../components/MenuBar";
import PageHeader from "../../../components/PageHeader";
import { supplierActions } from "../../../actions/supplier.actions";
import SupplierManagementTab from "./SupplierManagementTab";
import batchplus from "../../../assets/batch_plus.png";
import { Document, Page, pdfjs } from "react-pdf";
import aws from "aws-sdk";
import { Line, Circle } from "rc-progress";
import ProgressModal from "./ProgressModal";
import { getLocalStorage } from "../../../utils/helper/localStorage";
import { LOCAL_STORAGE_USER } from "../../../../constants";
import { Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import UpLoadSuccessDialog from "../../../components/UploadSuccessDialog";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import parse from "autosuggest-highlight/parse";
// import match from "autosuggest-highlight/match";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;
// pdfjs.GlobalWorkerOptions.workerSrc = "../../../utils/helper/pdf.worker.min.mjs";
pdfjs.GlobalWorkerOptions.workerSrc = "..";

export default function SupplierManagementFileList() {
  const dispatch = useDispatch();
  const userLogin = getLocalStorage(LOCAL_STORAGE_USER);

  const [body, setBody] = React.useState({
    orderBy: "name",
    direction: "DESC",
  });

  const [selectedSupId, setSelectedSupId] = React.useState(null);

  const top100Films = [];

  const defaultProps = {
    options: top100Films,
    getOptionLabel: (option) => option.name,
  };

  useEffect(() => {
    dispatch(supplierActions.getListFile(userLogin.id));
    // dispatch(supplierActions.getListFile(userLogin.id));
    // dispatch(supplierActions.getListSuppliers(body));
  }, []);

  const supppliers = useSelector((state) => state.supplier.suppliersList);
  useEffect(() => {
    if (supppliers?.items?.data?.suppliers?.length > 0) {
      var i;
      for (i = 0; i < supppliers.items.data.suppliers.length; i++) {
        supppliers.items.data.suppliers[i].label =
          supppliers.items.data.suppliers[i]["name"];
        supppliers.items.data.suppliers[i].key =
          supppliers.items.data.suppliers[i]["id"];
      }
      top100Films.push(...supppliers.items.data.suppliers);
    }
  }, [supppliers]);

  const selectedValues = React.useMemo(
    () => top100Films.filter((v) => v.selected),
    [top100Films]
  );

  console.log(supppliers?.items?.data?.suppliers);
  const fileList = useSelector((state) => state.supplier.files);
  const inputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [uploadFile, setUploadFile] = useState(false);
  const [progressUpload, setProgressUpload] = useState(-1);
  const [displayProgressModal, setDisplayProgressModal] = useState(false);
  const [linkPreview, setLinkPreview] = useState(null);
  const [percentLoading, setPercentLoading] = useState(0);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [supplierName, setSupplierName] = React.useState("");

  useEffect(() => {
    if (progressUpload === 100) {
      // dispatch(supplierActions.getListFile(userLogin.id));
      setUploadFile(false);
      setLinkPreview(null);
      setSelectedFile(null);
    }
    // dispatch(supplierActions.getListFile(userLogin.id));
  }, [progressUpload]);

  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let pdfFile = event.target.files[0];
      setFileName(pdfFile.name);
      let reader = new FileReader();
      reader.readAsDataURL(pdfFile);
      reader.onloadend = (e) => {
        setSelectedFile(pdfFile);
      };
    }
  };
  const onChooseFile = () => {
    if (supplierName === "") {
      notifyNotSelectedSup();
    } else {
      inputRef.current.click();
      setLinkPreview(null);
      resetPageNumber();
    }
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const onIncreasePagenumber = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const onDecreasePagenumber = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  const onUploadNewFile = () => {
    setUploadFile(true);
  };

  const closeProgressModal = () => {
    setDisplayProgressModal(false);
  };

  function previewPdfFile(linkFile) {
    setLinkPreview(linkFile);
  }

  function resetPageNumber() {
    setPageNumber(1);
  }

  async function saveFileToDb() {
    const data = {};
    data.admin_id = userLogin?.id;
    data.file_name = fileName;
    data.supplier_id = selectedSupId;
    data.file_s3_link =
      "https://" +
      import.meta.env.VITE_AWS_BUCKETNAME +
      ".s3.amazonaws.com/" +
      fileName;
    const result = await dispatch(supplierActions.uploadFile(data));
    // dispatch(supplierActions.getListSuppliers(body));
    // dispatch(supplierActions.getListFile(userLogin.id));
    setFileName(null);
    resetPageNumber();
  }

  const [pageNumber, setPageNumber] = useState(1);

  AWS.config.update({
    accessKeyId: import.meta.env.VITE_AWS_ACCESSKEYID,
    secretAccessKey: import.meta.env.VITE_AWS_SECRETACCESSKEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: import.meta.env.VITE_AWS_BUCKETNAME },
    region: import.meta.env.VITE_AWS_REGION,
  });

  async function generateSignedUrl() {
    const params = {
      Body: selectedFile,
      Bucket: import.meta.env.VITE_AWS_BUCKETNAME,
      Key: fileName,
    };

    myBucket
      .putObject(params)
      .on("build", (evt) => {
        setDisplayProgressModal(true);
        setProgressUpload(0);
      })
      .on("httpUploadProgress", (evt) => {
        setProgressUpload(Math.round((evt.loaded / evt.total) * 100));
      })
      .on("complete", () => {
        setDisplayProgressModal(false);
        setSuccessDialogOpen(true);
        saveFileToDb();
        setTimeout(() => {
          dispatch(supplierActions.getListFile(userLogin.id));
        }, "1000");
      })
      .send((err) => {
        if (err) console.log(err);
      });
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    if ("application/pdf" != event.dataTransfer.files[0].type) {
      notify();
    } else {
      setFileName(event.dataTransfer.files[0].name);
      setSelectedFile(event.dataTransfer.files[0]);
      resetPageNumber();
    }
  };

  const notify = () => {
    toast.error("PDF形式のファイルをアップロードしてください", {
      position: "top-right",
    });
  };

  const notifyNotSelectedSup = () => {
    toast.error("You have not selected any supplier", {
      position: "top-right",
    });
  };

  const handleSupplierChange = function (id) {
    if (id !== undefined) {
      setSelectedSupId(id);
      dispatch(supplierActions.getListSuppliers(body));
      setSelectedSupId(id);
    }
  };

  return (
    <>
      <div>
        <PageHeader />
        <div className="mt-[10px] flex flex-row h-screen">
          <MenuBar />
          <div className="pl-[2%] 2xl:w-[80%] xl:w-[80%] lg:w-[80%]">
            <div className="text-black text-xl font-medium ">仕入先管理</div>
            <div className="2xl:w-full xl:w-full lg:w-full h-px bg-zinc-300 mt-[8px]" />
            <div className="mt-[25px] text-sm font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
              <ul className="flex flex-wrap -mb-px">
                <SupplierManagementTab />
              </ul>
            </div>
            <div className="flex flex-row 2xl:w-full xl:w-full lg:w-full 2xl:h-[70%] bg-zinc-100 relative justify-between ">
              {!uploadFile && (
                <div className="w-[40%] h-full">
                  <div className="flex flex-col mt-[31px] ml-[17p]">
                    {/* <Autocomplete
                      {...defaultProps}
                      // disablePortal
                      key="somthing"
                      id="combo-box-demo"
                      blurOnSelect={true}
                      clearOnBlur={false}
                      openOnFocus={true}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      // renderOption={(props, option, { inputValue }) => {
                      //   const { key, ...optionProps } = props;
                      //   const matches = match(option.name, inputValue, { insideWords: true });
                      //   const parts = parse(option.name, matches);
                
                      //   return (
                      //     <li key={key} {...optionProps}>
                      //       <div>
                      //         {parts.map((part, index) => (
                      //           <span
                      //             key={index}
                      //             style={{
                      //               fontWeight: part.highlight ? 900 : 400,
                      //             }}
                      //           >
                      //             {part.text}
                      //           </span>
                      //         ))}
                      //       </div>
                      //     </li>
                      //   );
                      // }}
                      disableCloseOnSelect={true}
                      options={top100Films}
                      autoHighlight={true}
                      getOptionLabel={(option) => option.name || ""}
                      onChange={(e, value) => {
                        handleSupplierChange(value?.id);
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleSupplierChange(newInputValue?.id);
                      }}
                      autoComplete={true}
                      noOptionsText="Cannot find supplier"
                      sx={{ width: 300 }}
                      // value={selectedValues}
                      renderInput={(params) => (
                        <TextField {...params} label="仕入先管理" />
                      )}
                    /> */}
                    <div className="flex flex-row w-full h-[35px] bg-white items-center ">
                      {/* <input className="w-3.5 h-3.5 ml-[10px] bg-zinc-100 rounded-sm border border-zinc-100" />  */}
                      <div className="pl-[10px] w-[10%]  text-zinc-800 text-xs font-normal leading-3">
                        #
                      </div>
                      <div className="w-[20%] text-left text-black text-sm font-normal ">
                        ファイル名
                      </div>
                      <div className="w-[18%] text-left text-black text-sm font-normal ">
                        仕入先
                      </div>
                      <div className="w-[40%] text-left text-black text-sm font-normal">
                        アップロードユーザー名
                      </div>
                      <div className="w-[10%] text-left text-black text-sm font-normal">
                        
                      </div>
                    </div>
                    <div className="flex flex-col w-full max-h-[500px] h-[80%] bg-white opacity-80 overflow-y-auto">
                      {fileList &&
                        fileList.item &&
                        fileList.item.length > 0 &&
                        fileList.item.map((item, i) => {
                          return (
                            <div className="" key={item.id}>
                              <div
                                className="flex w-full h-[53px] flex items-center relative cursor-pointer"
                                onClick={() => {
                                  previewPdfFile(item.file_s3_link);
                                  setFileName(item.file_name);
                                  resetPageNumber();
                                }}
                              >
                                {/* <input className="ml-[10px] w-3.5 h-3.5 bg-zinc-100 rounded-sm border border-zinc-100" /> */}
                                <div className="pl-[10px] text-zinc-800 w-[10%] text-xs font-normal leading-3">
                                  {i + 1}
                                </div>
                                <Tooltip title={item.file_name} arrow>
                                  <span className="w-[20%]  text-black text-sm font-normal  truncate ">
                                    {item.file_name}
                                  </span>
                                </Tooltip>
                                <Tooltip title={item["supplier.name"]} placement="bottom-start">
                                  <span className="w-[20%] text-black text-sm font-normal  truncate ">
                                    {item["supplier.name"]}
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={item["admin.name"]}
                                  arrow
                                  placement="bottom-start"
                                >
                                  <span className="w-[45%] text-black text-sm font-normal truncate ">
                                    {item["admin.name"]}
                                  </span>
                                </Tooltip>
                                <div className="left-[258px] w-[0px] absolute text-black text-sm font-normal invisible truncate">
                                  {item.file_s3_link}
                                </div>
                              </div>
                              <div className="w-full h-px bg-zinc-300" />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="flex justify-center items-center bg-white opacity-80 h-[40px]">
                    <button
                      onClick={() => {
                        setUploadFile(true);
                        dispatch(supplierActions.getListSuppliers(body));
                      }}
                      className="w-[122px] h-6 bg-white rounded-[50px] border border-blue-900 text-center text-blue-900 text-xs font-bold hover:bg-gray-300"
                    >
                      新規価格表更新
                    </button>
                  </div>
                </div>
              )}
              {displayProgressModal && progressUpload <= 100 && (
                <ProgressModal progress={progressUpload}></ProgressModal>
              )}
              {uploadFile && (
                <div
                  className="flex flex-col items-center w-[40%] h-[508px] mt-[31px] ml-[17px] bg-white opacity-80"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <ToastContainer />
                  {!selectedFile && (
                    <div className="flex flex-col items-center">
                      <div className="flex flex-row items-center justify-between mt-[30px]">
                        <label className="text-2xl font-medium">仕入先 </label>
                        <Autocomplete
                        className="ml-[30px]"
                        {...defaultProps}
                        // disablePortal
                        key="somthing"
                        id="combo-box-demo"
                        blurOnSelect={true}
                        clearOnBlur={true}
                        openOnFocus={true}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.name}
                            </li>
                          );
                        }}
                        // renderOption={(props, option, { inputValue }) => {
                        //   const { key, ...optionProps } = props;
                        //   const matches = match(option.name, inputValue, { insideWords: true });
                        //   const parts = parse(option.name, matches);

                        //   return (
                        //     <li key={key} {...optionProps}>
                        //       <div>
                        //         {parts.map((part, index) => (
                        //           <span
                        //             key={index}
                        //             style={{
                        //               fontWeight: part.highlight ? 900 : 400,
                        //             }}
                        //           >
                        //             {part.text}
                        //           </span>
                        //         ))}
                        //       </div>
                        //     </li>
                        //   );
                        // }}
                        disableCloseOnSelect={true}
                        options={top100Films}
                        autoHighlight={true}
                        getOptionLabel={(option) => option.name || ""}
                        onChange={(e, value) => {
                          handleSupplierChange(value?.id);
                          setSupplierName(value.name);
                        }}
                        onInputChange={(event, newInputValue) => {
                          handleSupplierChange(newInputValue?.id);
                        }}
                        autoComplete={true}
                        noOptionsText="仕入先が見つかりません。"
                        sx={{ width: 300 }}
                        // value={selectedValues}
                        renderInput={(params) => (
                          <TextField {...params} label="仕入先管理" />
                        )}
                      />
                      </div>
                      
                      <label className="mt-[80px] text-black text-sm font-normal leading-3">
                        PDFファイルをドラッグ&ドロップしてください
                      </label>
                      <img
                        className="w-[97px] h-[99px] mt-[22px] hover:scale-110 cursor-pointer"
                        src={batchplus}
                        onClick={onChooseFile}
                      ></img>
                      <input
                        className="invisible"
                        id="file_input"
                        type="file"
                        accept=".pdf"
                        ref={inputRef}
                        onChange={handleOnChange}
                      />
                      <button
                        className="w-[167px] h-[18px] mt-[60px] bg-white rounded-[50px] border border-blue-900 text-center text-blue-900 text-xs font-bold hover:scale-110 hover:bg-gray-300"
                        onClick={onChooseFile}
                      >
                        PCからファイルを選択する
                      </button>
                    </div>
                  )}
                  {selectedFile && (
                    <div className="w-full h-[538px] bg-white opacity-80 flex flex-col items-center justify-center">
                      <div className="text-black text-2xl font-medium">
                        <span>仕入先: {supplierName}</span>
                      </div>
                      <div className="mt-[80px] text-black text-sm font-normal leading-3">
                        {fileName}
                      </div>
                      <div className="w-[450px] h-auto flex mt-[70px] justify-evenly">
                        <button className="w-[167px] h-[18px] bg-white rounded-[50px] border border-stone-300 PCからファイルを選択する text-center text-stone-300 text-xs font-bold">
                          PCからファイルを選択する
                        </button>
                        <button
                          className="w-[167px] h-[18px] bg-white rounded-[50px] border border-blue-900 text-center text-blue-900 text-xs font-bold hover:bg-gray-300"
                          onClick={() => {
                            generateSignedUrl();
                          }}
                        >
                          更新する
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {selectedFile && (
                <div className="w-[450px] h-[550px] flex flex-col mt-[6px] mr-[114px] ">
                  <div className="w-full h-[20px] text-black text-sm font-normal ">
                    ファイル名: {fileName}
                  </div>
                  <div className="w-full h-[538px] bg-white mt-[5px] p-[7px]">
                    <Document
                      file={selectedFile}
                      width={200}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading={
                        <div className="flex justify-center w-full items-center">
                          <span className="mr-[10px]">Loading PDF File...</span>
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress color="secondary" />
                          </Box>
                        </div>
                      }
                    >
                      <Page
                        pageNumber={pageNumber}
                        renderTextLayer={false}
                        height={547}
                        width={500}
                      />
                    </Document>
                  </div>
                  {selectedFile && (
                    <div className="flex justify-center mt-[10px] text-center text-blue-900 text-xs font-bold ">
                      {pageNumber > 1 && (
                        <button className="" onClick={onDecreasePagenumber}>
                          ◀️
                        </button>
                      )}
                      <span className="">
                        {pageNumber}/{numPages}
                      </span>
                      {pageNumber < numPages && (
                        <button className="" onClick={onIncreasePagenumber}>
                          ▶️
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
              {linkPreview && (
                <div className="w-[450px] h-[547px] flex flex-col mt-[6px] mr-[114px] ">
                  <div className="full h-[20px] text-black text-sm font-normal">
                    ファイル名: {fileName}
                  </div>
                  <div className="w-full h-[538px] bg-white mt-[5px] p-[7px]">
                    <Document
                      width={100}
                      file={{
                        url: linkPreview,
                      }}
                      onLoadSuccess={onDocumentLoadSuccess}
                      // onLoadProgress={(loaded, total) =>
                      //   console.log((loaded.loaded / loaded.total) * 100)
                      // }
                      loading={
                        <div className="flex justify-center w-full items-center">
                          <span className="mr-[10px]">Loading PDF File...</span>
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress color="secondary" />
                          </Box>
                        </div>
                      }
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                  </div>

                  <div className="flex justify-center mt-[10px] text-center text-blue-900 text-xs font-bold ">
                    {pageNumber > 1 && (
                      <button className="" onClick={onDecreasePagenumber}>
                        ◀️
                      </button>
                    )}
                    <span className="">
                      {pageNumber}/{numPages}
                    </span>
                    {pageNumber < numPages && (
                      <button className="" onClick={onIncreasePagenumber}>
                        ▶️
                      </button>
                    )}
                  </div>
                </div>
              )}
              <ProgressModal
                open={displayProgressModal}
                progress={progressUpload}
                onClose={() => closeProgressModal()}
              />
              <UpLoadSuccessDialog
                open={successDialogOpen}
                onClose={() => {
                  setSuccessDialogOpen(false);
                }}
                message={"価格表の更新が\n完了しました"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
