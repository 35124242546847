import { Dialog } from "@mui/material";
import React from "react";

export default function DialogCompletedCreateNoti({ open, onClose, message }) {
  return (
    <Dialog open={open}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="w-[564px] h-[164px] flex-col justify-start items-start inline-flex">
          <div className="h-[164px] px-20 py-6 bg-white rounded-[10px] shadow flex-col justify-start items-center gap-[30px] flex">
            <div className="w-[372px] h-fit text-center text-zinc-800 text-lg font-normal ">
              {message.split("\r\n").map((text, index) => (
                <React.Fragment key={`${index}-${text}`}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </div>
            <div
              onClick={() => onClose()}
              className="justify-start items-start inline-flex"
            >
              <div className="w-[120px] h-8 px-3 pt-[3px] pb-1 bg-[#a09c9c] rounded-[50px] justify-center items-center gap-2.5 flex">
                <div className="w-[120px] h-3 relative flex justify-center items-center">
                  <div className="absolute text-center text-white text-sm font-bold  leading-3">
                    <button>閉じる</button>
                  </div>
                  <div className="w-[120px] h-[0px] left-0 top-[6.50px] absolute"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
