import { Dialog } from "@mui/material";
import React from "react";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

export default function DeleteUserConfirm({
  open,
  onClose,
  onConfirm,
  numberUser,
}) {
  const handleClickAway = () => {
    onClose();
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex-col justify-start items-start flex">
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="h-40 px-20 py-8 bg-white rounded-lg shadow flex-col justify-start items-center gap-10 flex">
              <div className="text-center text-zinc-800 text-lg font-normal ">
                {numberUser == 0 ? 1 : numberUser}件のユーザーを削除しますか？
              </div>
              <div className="justify-start items-start gap-5 inline-flex">
                <div className="justify-start items-start flex">
                  <div
                    className="w-28 h-8 px-3 pt-0.5 pb-1 bg-white rounded-3xl border border-stone-500 justify-center items-center gap-2.5 flex cursor-pointer hover:bg-gray-300 active:bg-gray-300"
                    onClick={() => onClose()}
                  >
                    <div className="w-28 h-3 relative">
                      <div className="bg-white/opacity-60  left-[6px] top-0 absolute text-center text-stone-500 text-sm font-bold  leading-3">
                        <button>キャンセル</button>
                      </div>
                      <div className="w-28 h-px left-0 top-[6.50px] absolute"></div>
                    </div>
                  </div>
                </div>
                <div className="justify-start items-start flex">
                  <div
                    className="w-28 h-8 px-3 pt-0.5 pb-1 bg-[#cb3030] rounded-3xl justify-center items-center gap-2.5 flex cursor-pointer hover:bg-orange-600 active:bg-orange-600"
                    onClick={() => onConfirm()}
                  >
                    <div className="w-28 h-3 relative">
                      <div className="left-[16px] top-0 absolute text-center text-white text-sm font-bold  leading-3">
                        <button>削除する</button>
                      </div>
                      <div className="w-28 h-px left-0 top-[6.50px] absolute"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </Dialog>
  );
}
