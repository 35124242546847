import React from "react";

export default function RegistrationDialogSuccess() {
  return (
    <div className="w-[420px] h-[164px] flex-col justify-start items-start inline-flex">
      <div className="h-[164px] px-20 py-6 bg-white rounded-[10px] shadow flex-col justify-start items-center gap-[30px] flex">
        <div className="w-[372px] h-[54px] text-center text-zinc-800 text-lg font-normal ">
          1件の仕入先登録が
          <br />
          完了しました
        </div>
        <div className="flex justify-start items-start inline-flex">
          <div className="w-[120px] h-8 px-3 pt-[3px] pb-1 bg-blue-900 rounded-[50px] justify-center items-center gap-2.5 flex cursor-pointer">
            <div className="w-[120px] h-3 relative">
              <div className="left-[50px] top-0 text-center text-white text-sm font-bold  leading-3">
                OK
              </div>
              <div className="w-[120px] h-[0px] left-0 top-[6.50px] absolute"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
