import React from "react";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../actions";
import { getLocalStorage } from "../../../utils/helper/localStorage";
import { LOCAL_STORAGE_USER } from "../../../../constants";
import { useForm } from "react-hook-form";
import { userConstants } from "../../../constants";
import { Dialog, Tooltip } from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import hide from "../../../assets/hide.png";
import show from "../../../assets/show.png";
// import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import $ from "jquery";

function UserRegistration({ open, onClose, onSubmit }) {
  const [isError, setIsError] = React.useState(true);
  const [isRequired, setIsRequired] = React.useState(true);
  const [requiredValue, setRequiredValue] = React.useState([]);
  const [isSelected, setIsSelected] = React.useState(false);
  const dispatch = useDispatch();
  const current_date = Moment(new Date()).format("YYYY/MM/DD");
  const userLogin = getLocalStorage(LOCAL_STORAGE_USER);
  const create = useSelector((state) => state.user.create);
  // const [isHide, setIsHide] = React.useState(true);
  // const [isEyeIconVisible, setIsEyeIconVisible] = React.useState(false);

  // const togglePasswordVisibility = () => {
  // setIsHide(!isHide);
  // };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    reset,
    setValue,
  } = useForm();

  React.useEffect(() => {
    setIsRequired(true);
    setIsSelected(false);
    setValue("age", "0");
    setValue("gender", "4");
  }, [open]);

  const handleFormSubmit = async (data) => {
    data.admin_id = userLogin?.id;
    data.approve_status = 2;

    await dispatch(userActions.create(data));
  };

  const handleChangeRequiredValue = (e) => {
    if (e.target?.value) {
      setRequiredValue({
        ...requiredValue,
        [`${e.target.name}`]: true,
      });
    } else {
      setRequiredValue({
        ...requiredValue,
        [`${e.target.name}`]: false,
      });
    }
  };

  React.useEffect(() => {
    if (
      !requiredValue["name"] ||
      !requiredValue["password_hash"] ||
      !requiredValue["company_working_at_name"] ||
      !requiredValue["email"]
    ) {
      setIsRequired(true);
      setIsError(true);
    } else {
      setIsRequired(false);
      setIsError(false);
    }
  }, [requiredValue]);

  React.useEffect(() => {
    if (create.item) {
      dispatch({ type: userConstants.CREATE_SUCCESS, payload: null });
      onSubmit();
      reset();
      setRequiredValue([]);
    } else if (create.error) {
      if (create.error.email) {
        const formError = {
          type: "server",
          message: create.error.email.message,
        };
        setError("email", formError);
      }
    }
  }, [create]);

  const handleChangeSelect = (e) => {
    if (e.target.value) {
      setIsSelected(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose(false);
    reset();
    setIsRequired(true);
    setRequiredValue([]);
    setValue("age", "");
  };

  $(function () {
    $("#age").each(function () {
      this.style.setProperty("color", "#000", "important");
    });
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <form
        {...register("admin_id", "created_at", "updated_at", "approve_status")}
      >
        <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
          <ClickAwayListener onClickAway={handleClose}>
            <div className="relative flex flex-col w-fit h-fit bg-white rounded-[10px] pr-[20px] overflow-y-auto">
              <div className="text-black flex items-center h-[94px] ml-[22px] text-black text-xl font-bold">
                ユーザー新規登録
              </div>
              <div className="w-[815px] h-px bg-zinc-300 ml-[22px]" />

              <div className="flex flex-row ml-[22px] mt-[8px] items-center">
                <span className="text-black text-sm font-normal ">
                  ユーザー名
                </span>
                <input
                  {...register("name", {
                    required: "ユーザー名を入力してください",
                    onChange: (e) => handleChangeRequiredValue(e),
                  })}
                  name="name"
                  className={
                    errors.name
                      ? "p-2 w-[670px] h-[35px] bg-white rounded-[10px] border border-red-500 ml-[70px] text-sm"
                      : "p-2 w-[670px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[70px] text-sm"
                  }
                  placeholder="東洋　太郎"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "東洋　太郎")}
                />
              </div>
              {errors.name && (
                <div className="ml-[163px] w-fit text-red-500 mb-[15px] h-px">
                  <span>{errors.name.message}</span>
                </div>
              )}

              <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[8px] pt-[1px]"></div>
              <div className="flex flex-row ml-[22px] mt-[10px] items-center relative">
                <span className="text-black text-sm font-normal ">
                  パスワード
                </span>
                <input
                  // type={isHide ? "password" : "text"}
                  type="password"
                  {...register("password_hash", {
                    required: "パスワードを入力してください",
                    pattern: {
                      value:
                        /^(?=.*?[0-9])(?=.*?[A-Za-z]).{8,}$/,
                      message:
                        "半角英数字と記号を組み合わせた8文字以上のパスワードを入力してください",
                    },
                    onChange: (e) => handleChangeRequiredValue(e),
                  })}
                  name="password_hash"
                  className="p-2 w-[291px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[70px] text-sm"
                  placeholder="半角英数字、記号の組み合わせ8文字以上"
                  onFocus={(e) => {
                    e.target.placeholder = "";
                  }}
                  onBlur={(e) => {
                    e.target.placeholder =
                      "半角英数字、記号の組み合わせ8文字以上";
                  }}
                />
              </div>
              {errors.password_hash && (
                <div className="ml-[163px] w-fit text-red-500 mb-[15px] h-px">
                  <span>{errors.password_hash.message}</span>
                </div>
              )}

              <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[8px] pt-[1px]" />
              <div className="flex flex-row ml-[22px] mt-[10px] items-center">
                <span className="text-black text-sm font-normal ">
                  在籍企業名
                </span>
                <input
                  {...register("company_working_at_name", {
                    required: "在籍企業名を入力してください",
                    onChange: (e) => handleChangeRequiredValue(e),
                  })}
                  name="company_working_at_name"
                  className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[70px] text-sm"
                  placeholder="東洋舗材株式会社"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "東洋舗材株式会社")}
                />
              </div>
              {errors.company_working_at_name && (
                <div className="ml-[163px] w-fit text-red-500 mb-[15px] h-px">
                  <span>{errors.company_working_at_name.message}</span>
                </div>
              )}

              <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[8px] pt-[1px]" />
              <div className="flex flex-row ml-[22px] mt-[10px] items-center">
                <span className="text-black text-sm font-normal ">
                  在籍企業電話番号
                </span>
                <input
                  {...register("company_working_at_phone_number", {
                    pattern: {
                      value: /^[0-9０-９]*$/,
                      message: "有効な企業電話番号（英数字のみ）を入力してください",
                    },
                  })}
                  name="company_working_at_phone_number"
                  className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[30px] text-sm"
                  placeholder="080-1234-5678"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "080-1234-5678")}
                />
              </div>
              {errors.company_working_at_phone_number && (
                <div className="ml-[163px] w-fit text-red-500 mb-[15px] h-px">
                  <span>{errors.company_working_at_phone_number.message}</span>
                </div>
              )}

              <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[8px] pt-[1px]" />
              <div className="flex flex-row ml-[22px] mt-[10px] items-center">
                <span className="text-black text-sm font-normal ">
                  メールアドレス
                </span>
                <input
                  onChange={(e) => handleChangeRequiredValue(e)}
                  {...register("email", {
                    required: "メールアドレスを入力してください",
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "有効なメールアドレスを入力してください",
                    },
                    onChange: (e) => handleChangeRequiredValue(e),
                  })}
                  name="email"
                  className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[43px] text-sm"
                  placeholder="◯◯◯◯@gmail.com"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "◯◯◯◯@gmail.com")}
                />
              </div>
              {errors.email && (
                <div className="ml-[163px] w-fit text-red-500 mb-[15px] h-px">
                  <span>{errors.email.message}</span>
                </div>
              )}

              <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[8px] pt-[1px]" />
              <div className="flex flex-row ml-[22px] mt-[24px]">
                <span className="text-black text-sm font-normal ">
                  性別（任意）
                </span>
                <input
                  {...register("gender")}
                  id="default-radio-1"
                  type="radio"
                  value="1"
                  name="gender"
                  class="ml-[57px] w-4 h-4"
                />
                <label
                  for="default-radio-1"
                  class="ms-2 text-sm font-medium mr-4"
                >
                  男性
                </label>
                <input
                  {...register("gender")}
                  id="default-radio-2"
                  type="radio"
                  value="2"
                  name="gender"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                />
                <label
                  for="default-radio-2"
                  class="ms-2 text-sm font-medium mr-4"
                >
                  女性
                </label>
                <input
                  {...register("gender")}
                  id="default-radio-3"
                  type="radio"
                  value="3"
                  name="gender"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                />
                <label
                  for="default-radio-3"
                  class="ms-2 text-sm font-medium mr-4"
                >
                  その他
                </label>
                <input
                  {...register("gender")}
                  id="default-radio-4"
                  type="radio"
                  value="4"
                  name="gender"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                />
                <label
                  for="default-radio-4"
                  class="ms-2 text-sm font-medium mr-4"
                >
                  回答しない
                </label>
              </div>
              {errors.gender && (
                <div className="ml-[163px] w-fit text-red-500 mb-[15px] h-px">
                  <span>{errors.gender.message}</span>
                </div>
              )}

              <div className="flex flex-row ml-[22px] mt-[15px] items-center">
                <span className="text-black text-sm font-normal ">
                  年齢（任意）
                </span>
                <select
                  id="age"
                  {...register("age", {
                    onChange: (e) => {
                      handleChangeSelect(e);
                      handleChangeRequiredValue(e);
                    },
                  })}
                  name="age"
                  className={`${
                    isSelected ? "text-black" : ""
                  } p-1 w-40 h-8 bg-white rounded-[10px] border border-stone-300 ml-[55px] text-sm`}
                >
                  <option
                    // disabled="disabled"
                    className="!text-black text-sm"
                    value="0"
                    selected
                  >
                    未回答
                  </option>
                  <option className="text-black" value="1">
                    10-19歳
                  </option>
                  <option className="text-black" value="2">
                    20-29歳
                  </option>
                  <option className="text-black" value="3">
                    30-39歳
                  </option>
                  <option className="text-black" value="4">
                    40-49歳
                  </option>
                  <option className="text-black" value="5">
                    50-59歳
                  </option>
                  <option className="text-black" value="6">
                    60-69歳
                  </option>
                  <option className="text-black" value="7">
                    70-79歳
                  </option>
                </select>
              </div>
              {errors.age && (
                <div className="ml-[163px] w-fit text-red-500 mb-[15px] h-px">
                  <span>{errors.age.message}</span>
                </div>
              )}

              <div className="flex flex-row ml-[22px] mt-[18px]">
                <span className="text-black text-sm font-normal ">
                  アカウント登録日
                </span>
                <span className="text-black text-sm font-normal ml-[30px]">
                  {current_date}
                </span>
              </div>
              <div className="flex flex-row ml-[22px] mt-[30px]">
                <span className="text-black text-sm font-normal ">
                  アカウント登録者ID
                </span>
                {/* <Tooltip title={userLogin.name} arrow>
                  <span className="text-black text-sm font-normal ml-[18px]">
                    {userLogin.name.length <= 20
                      ? userLogin.name
                      : userLogin.name.substring(0, 20) + "..."}
                  </span>
                </Tooltip> */}
                <Tooltip title={"100" + userLogin?.id} arrow>
                  <span className="text-black text-sm font-normal ml-[17px]">
                    {"100" + userLogin?.id}
                  </span>
                </Tooltip>
              </div>
              <div className="flex mt-[23px] ml-[228px] mb-[20px]">
                <button
                  type="button"
                  className=" w-[193px] h-[39px] bg-white/opacity-60 rounded-[50px] border border-black/opacity-20 text-stone-300 text-xl font-bold focus:outline-none focus:ring-2 focus:border-transparent hover:bg-slate-100"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  キャンセル
                </button>
                <button
                  disabled={isError}
                  type="button"
                  className={`${
                    isError ? "bg-[#748bbe]" : "bg-blue-900"
                  } w-[193px] h-[39px] ml-[50px]  rounded-[50px] text-white text-xl font-bold hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300`}
                  onClick={handleSubmit(handleFormSubmit)}
                >
                  新規登録
                </button>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </form>
    </Dialog>
  );
}

export default UserRegistration;
