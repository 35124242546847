import React from "react";
import routes from "../routes";
import { useRoutes } from "react-router-dom";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import NetworkStatusIndicator from "./components/NetworkStatusIndicator";

const theme = createTheme({
  palette: {
    primary: {
      50: "#21412a",
      100: "#21412a",
      200: "#21412a",
      300: "#21412a",
      400: "#21412a",
      500: "#21412a",
      600: "#21412a",
      700: "#21412a",
      800: "#21412a",
      900: "#21412a",
      A100: "#21412a",
      A200: "#21412a",
      A400: "#21412a",
      A700: "#21412a",
      contrastDefaultColor: "light",
    },
  },
});

const App = React.memo(() => {
  const routing = useRoutes(routes);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{routing}</ThemeProvider>
    </StyledEngineProvider>
  );
});

export default App;
