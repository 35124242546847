import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function SupplierManagementTab() {
  const location = useLocation();
  const navigate = useNavigate();
  const patternDetailSuppluer = /supplier-management-list/;
  return (
    <>
      <li
        className={`${
          location.pathname == "/supplier-management-map"
            ? "bg-zinc-100"
            : "bg-[#b9b9b9]"
        } me-1 w-[140px] h-[30px] px-[22px] py-[9px] rounded-tl rounded-tr justify-center items-center gap-2.5 inline-flex cursor-pointer`}
        onClick={() => navigate("/supplier-management-map")}
      >
        <Link
          to="/supplier-management-map"
          className="text-center text-neutral-500 text-xs font-bold"
        >
          仕入先マップ
        </Link>
      </li>

      <li
        className={`${
          patternDetailSuppluer.test(location.pathname)
            ? "bg-zinc-100"
            : "bg-[#b9b9b9]"
        } me-1 w-[140px] h-[30px] px-[22px] py-[9px] rounded-tl rounded-tr justify-center items-center gap-2.5 inline-flex cursor-pointer`}
        onClick={() => navigate("/supplier-management-list")}
      >
        <Link
          to="/supplier-management-list"
          className="text-center text-neutral-500 text-xs font-bold"
        >
          仕入先一覧
        </Link>
      </li>
      <li
        className={`${
          location.pathname == "/supplier-management-files"
            ? "bg-zinc-100"
            : "bg-[#b9b9b9]"
        } me-2 w-[140px] h-[30px] px-[22px] py-[9px] rounded-tl rounded-tr justify-center items-center gap-2.5 inline-flex cursor-pointer`}
        onClick={() => navigate("/supplier-management-files")}
      >
        <Link
          to="/supplier-management-files"
          className="text-center text-neutral-500 text-xs font-bold"
        >
          仕入先価格表
        </Link>
      </li>
    </>
  );
}