import React from "react";
import { useNavigate } from "react-router-dom";
import {
  // BASE_API,
  EXTERNAL_BASE_API,
  LOCAL_STORAGE_TOKEN,
  LOCAL_STORAGE_USER,
} from "../../constants.js";
import { setLocalStorage } from "../utils/helper/localStorage";
import { Dialog } from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

function LogoutConfirmDialog({ open, onClose }) {
  const navigate = useNavigate();
  const handleLogout = async (e) => {
    e.preventDefault();
    setLocalStorage(LOCAL_STORAGE_TOKEN, "");
    setLocalStorage(LOCAL_STORAGE_USER, "");
    window.location.href = "/login";
  };

  // React.useEffect(() => {
  //   debugger;
  //   function handleEscapeKey(event) {
  //     debugger;
  //     if (event.code === "Escape") {
  //       onClose();
  //     }
  //   }

  //   document.addEventListener("keydown", handleEscapeKey);
  //   return () => document.removeEventListener("keydown", handleEscapeKey);
  // }, []);

  const handleClickAway = () => {
    onClose();
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className="w-[420px] h-[164px] px-20 py-8 bg-white rounded-[10px] shadow flex-col justify-start items-center gap-[42px] inline-flex">
                <div className="text-center text-zinc-800 text-lg font-normal ">
                  ログアウトしますか？
                </div>
                <div className="justify-start items-start gap-5 inline-flex">
                  <div className="justify-start items-start flex">
                    <div
                      className="w-[120px] h-8 px-3 pt-[3px] pb-1 bg-white rounded-[50px] border border-stone-500 justify-center items-center gap-2.5 flex hover:bg-gray-300 active:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-300 cursor-pointer"
                      onClick={() => onClose()}
                    >
                      <div className="w-[120px] h-3 relative">
                        <button className="left-[15px] w-auto top-0 absolute text-center text-[#989898] text-sm font-bold leading-3 ">
                          キャンセル
                        </button>
                        <div className="w-[120px] h-[0px] left-0 top-[6.50px] absolute"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="justify-start items-start flex "
                    onClick={(e) => handleLogout(e)}
                  >
                    <div className="w-[120px] h-8 px-3 pt-[3px] pb-1 bg-blue-900 rounded-[50px] justify-center items-center gap-2.5 flex hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 cursor-pointer">
                      <div className="w-[120px] h-3 relative">
                        <button className="left-[15px] top-0 absolute text-center text-white text-sm font-bold leading-3 ">
                          ログアウト
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </Dialog>
  );
}

export default LogoutConfirmDialog;
