import { Dialog } from "@mui/material";
import React from "react";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

export default function DialogConfirmCreateNoti({
  open,
  onClose,
  onConfirm,
  message,
}) {
  const handleClickAway = () => {
    onClose();
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="w-[500px] h-fit flex-col">
            <div className="h-[164px] px-20 py-6 bg-white rounded-[10px] shadow flex-col justify-start items-center gap-[30px] flex">
              <div className="w-[372px] h-[54px] text-center text-zinc-800 text-lg font-normal flex flex-col">
                {/* {
              message.split("\\n+").map((text, index) => (
                <React.Fragment key={`${index}-${text}`}>
                  {text}
                  <br />
                </React.Fragment>
              ))} */}
                <div className="text-lg">お知らせを作成しますか？</div>

                <div className="text-sm mt-[15px]">
                  ※公開設定を「公開」にしている場合、
                  <br />
                  お知らせはユーザに公開されます
                </div>
              </div>
              <div className="w-[300px] justify-between items-start inline-flex mt-[10px]">
                <div
                  onClick={() => onClose()}
                  className="w-fit h-8 px-3 pt-[3px] pb-1 bg-[#a09c9c] rounded-[50px] justify-center items-center gap-2.5 flex cursor-pointer"
                >
                  <div className="w-[120px] h-3 relative flex items-center justify-center">
                    <div className=" w-fit text-center text-white text-sm font-bold leading-3 ">
                      <button>キャンセル</button>
                      {/* <button>作成する</button> */}
                    </div>
                    <div className="w-[120px] h-[0px] left-0 top-[6.50px] absolute"></div>
                  </div>
                </div>
                <div
                  onClick={() => onConfirm()}
                  className="w-fit h-8 px-3 pt-[3px] pb-1 bg-blue-900 rounded-[50px] justify-center items-center gap-2.5 flex cursor-pointer"
                >
                  <div className="w-[120px] h-3 relative flex items-center justify-center">
                    <div className="w-fit text-center text-white text-sm font-bold  leading-3 flex">
                      <button>作成する</button>
                    </div>
                    <div className="w-[120px] h-[0px] left-0 top-[6.50px] absolute"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </Dialog>
  );
}
