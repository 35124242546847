import React, { useRef, useImperativeHandle } from "react";
import PageHeader from "../../../components/PageHeader";
import MenuBar from "../../../components/MenuBar";
import { useForm } from "react-hook-form";
import { LOCAL_STORAGE_USER } from "../../../../constants";
import { notificationActions } from "../../../actions";
import DialogCompletedCreateNoti from "../../../components/DialogCompletedCreateNoti";
import DialogConfirmCreateNoti from "../../../components/DialogConfirmCreateNoti";
import { useDispatch } from "react-redux";
import { getLocalStorage } from "../../../utils/helper/localStorage";
import Moment from "moment";
import { Link, useNavigate } from "react-router-dom";

export default function NotificationsRegister() {
  const dispatch = useDispatch();
  const userLogin = getLocalStorage(LOCAL_STORAGE_USER);
  const [messageSuccess, setMessageSuccess] = React.useState("");
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const formRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  useImperativeHandle(formRef, () => ({
    submitForm() {
      handleSubmit(handleFormSubmit)();
    },
  }));

  const handleFormSubmit = async (data) => {
    data.admin_id = userLogin?.id;
    await dispatch(notificationActions.create(data));
    setSuccessDialogOpen(true);
    setMessageSuccess("お知らせを作成しました");
    setIsOpen(true);
  };

  return (
    <div>
      <div
        className={isOpen ? "opacity-25 fixed inset-0 z-40 bg-black" : ""}
      ></div>
      <PageHeader />
      <div className="mt-[10px] flex flex-row h-[800px]">
        <MenuBar />
        <div className="pl-[21px] w-full pr-[21px]">
          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="flex flex-col"
          >
            <div className="text-black text-xl font-medium ">
              お知らせ新規作成{" "}
            </div>
            <div className="w-full h-px bg-zinc-300 mt-[8px] " />
            <div className="text-black text-xl font-medium mt-[40px]">
              内傘 ※30文字まで
            </div>
            <input
              type="text"
              {...register("notification_contents", {
                required: true,
                maxLength: 30,
              })}
              className="text-left text-xl p-2 w-[1170px] 2xl:w-10/12 h-[100px] bg-[#efefef] rounded-lg mt-[10px] mr-[20px]"
            />
            {errors.notification_contents?.type === "required" && (
              <span className="text-red-900 text-[18px]">
                This field is required
              </span>
            )}
            {errors.notification_contents?.type === "maxLength" && (
              <span className="text-red-900 text-[18px]">
                This field max length is 30
              </span>
            )}
            <div className="text-black text-xl font-medium mt-[30px]">
              遷移先リンク
            </div>
            <input
              {...register("link", { required: true })}
              className="text-lg p-2 w-[1170px] h-[50px] bg-[#efefef] rounded-lg mt-[10px] 2xl:w-10/12"
            />
            {errors.link?.type === "required" && (
              <span className="text-red-900 text-[18px]">
                This field is required
              </span>
            )}
            <label className="mt-[20px]">公開設定</label>

            <div className="flex flex-col ml-[20px] mt-[20px]">
              <div className="flex">
                <input
                  {...register("is_public")}
                  checked
                  className=""
                  type="radio"
                  name="is_public"
                  value="1"
                  id="public"
                />
                <label htmlFor="public">公開する</label>
              </div>
              <div className="flex">
                <input
                  {...register("is_public")}
                  className=""
                  type="radio"
                  name="is_public"
                  value="0"
                  id="unpublic"
                />
                <label htmlFor="unpublic">非公開にする</label>
              </div>
            </div>

            <div className="flex justify-center w-[1170px] mt-[40px] 2xl:w-10/12">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/notifications");
                }}
                className="bg-slate-400 w-[400px] h-[40px] rounded-md text-white text-[20px] font-bold font-['Montserrat'] text-center"
              >
                <Link to={`/notifications`}>キャンセル</Link>
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmDialogOpen(true);
                  setIsOpen(true);
                }}
                className="ml-[30px] bg-blue-900 w-[400px] h-[40px] rounded-md text-white text-[20px] font-bold font-['Montserrat'] text-center"
              >
                お知らせを発信する
              </button>
            </div>
          </form>
        </div>
      </div>
      <DialogConfirmCreateNoti
        open={confirmDialogOpen}
        message="お知らせを作成しますか？ \n※公開設定を「公開」にしている場合、\nお知らせはユーザに公開されます"
        onConfirm={() => formRef.current.submitForm()}
        onClose={() => {
          setConfirmDialogOpen(false);
          setIsOpen(false);
        }}
      />
      <DialogCompletedCreateNoti
        open={successDialogOpen}
        message="お知らせを作成しました"
        onClose={() => {
          setSuccessDialogOpen(false);
          setIsOpen(false);
          navigate("/notifications");
        }}
      />
    </div>
  );
}
