import React, { useState, useEffect } from "react";
import { Line, Circle } from "rc-progress";
import { Dialog, ClickAwayListener } from "@mui/material";

export default function ProgressModal({ open, progress, onClose }) {
  const handleClickAway = () => {
    onClose();
  };

  const handleClose = (event, reason) => {
    onClose();
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-3xl font-semibold">アップロード進捗</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <Circle
                  className="width-30px"
                  percent={progress}
                  strokeWidth={4}
                  strokeColor="#D3D3D3"
                />
              </div>
              {/*footer*/}
              <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                <label
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  {progress}%
                </label>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </Dialog>
  );
}
