export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  CHANGEGROUP: 'USERS_CHANGEGROUP',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  GETLOGGEDIN_REQUEST: 'USERS_GETLOGGEDIN_REQUEST',
  GETLOGGEDIN_SUCCESS: 'USERS_GETLOGGEDIN_SUCCESS',
  GETLOGGEDIN_FAILURE: 'USERS_GETLOGGEDIN_FAILURE',

  GET_USERS_REQUEST: 'USERS_GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'USERS_GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'USERS_GET_USERS_FAILURE',

  CREATE_REQUEST: 'USERS_CREATE_REQUEST',
  CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
  CREATE_FAILURE: 'USERS_CREATE_FAILURE',

  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  GET_UNAPPROVE_USERS_REQUEST: 'GET_UNAPPROVE_USERS_REQUEST',
  GET_UNAPPROVE_USERS_SUCCESS: 'GET_UNAPPROVE_USERS_SUCCESS',
  GET_UNAPPROVE_USERS_FAILURE: 'GET_UNAPPROVE_USERS_FAILURE',

  REJECT_REQUEST: 'USERS_REJECT_REQUEST',
  REJECT_SUCCESS: 'USERS_REJECT_SUCCESS',
  REJECT_FAILURE: 'USERS_REJECT_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  GETUSER_SUCCESS: 'USERS_GETUSER_SUCCESS',
  GETUSER_FAILURE: 'USERS_GETUSER_FAILURE',

  GETBYID_REQUEST: 'USERS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'USERS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'USERS_GETBYID_FAILURE',

  APPROVE_REQUEST: 'USERS_APPROVE_REQUEST',
  APPROVE_SUCCESS: 'USERS_APPROVE_SUCCESS',
  APPROVE_FAILURE: 'USERS_APPROVE_FAILURE',

  GETUSERMENU_SUCCESS: 'USERS_GETUSERMENU_SUCCESS',

  ISSUEPASSWORD_REQUEST: 'USERS_ISSUEPASSWORD_REQUEST',
  ISSUEPASSWORD_SUCCESS: 'USERS_ISSUEPASSWORD_SUCCESS',
  ISSUEPASSWORD_FAILURE: 'USERS_ISSUEPASSWORD_FAILURE',

  GETLOGS_REQUEST: 'USERS_GETLOGS_REQUEST',
  GETLOGS_SUCCESS: 'USERS_GETLOGS_SUCCESS',
  GETLOGS_FAILURE: 'USERS_GETLOGS_FAILURE',
};
