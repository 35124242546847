import * as React from "react";

const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;

export default function useNavigatorOnLine() {
  // const [status, setStatus] = React.useState(getOnLineStatus());
  const [status, setStatus] = React.useState(true);

  React.useEffect(() => {
    const interval = setInterval(() => {
      fetch("https://www.google.com/", {
        mode: "no-cors",
      })
        .then(() => !status && setStatus(true))
        .catch(() => status && setStatus(false));
    }, 5000);
    return () => clearInterval(interval);
  }, [status]);

  return status;
}
