import React, { Fragment, FunctionComponent } from "react";
import { useEffect } from "react";
import { useState } from "react";
import PageHeader from "../../../components/PageHeader";
import MenuBar from "../../../components/MenuBar";
import SupplierManagementTab from "./SupplierManagementTab";
import { useDispatch, useSelector } from "react-redux";
import { mapActions } from "../../../actions/map.actions";
import leftlinearrow from "../../../assets/leftlinearrow.png";
import direction from "../../../assets/directionv1.png";
import { Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { NavLink } from "react-router-dom";

import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  useAdvancedMarkerRef,
  InfoWindow,
} from "@vis.gl/react-google-maps";

export default function SupplierManagementMap() {
  const positions = { latitude: 21.0278, longtitude: 105.8342 };
  // const [markerRef, marker] = useAdvancedMarkerRef();
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [detail, setDetail] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const API_KEY = import.meta.env.VITE_GOOGLE_MAP_API_KEY;
  const MAP_LANGUAGE = import.meta.env.VITE_GOOGLE_MAP_LANGUAGE;
  const [suppliersFilter, setSuppliersFilter] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [centerObj, setCenterObj] = useState(null);
  const [zoomIn, setZoomIn] = useState(null);
  const [lat, setLat] = React.useState(0);
  const [long, setLong] = React.useState(0);
  const [index, setIndex] = React.useState(-1);
  const [searchAction, setSearchAction] = React.useState(false);
  const [currentPinColor, setCurrentPinColor] = React.useState(0);
  const [currentSupplierId, setCurrentSupplierId] = React.useState(0);
  const [currentSupplierPage, setCurrentSupplierPage] = React.useState(-1);

  const handleKeyDown = (event) => {
    if ("Escape" == event.key) {
      setInfowindowOpen(false);
    }
  };

  const center = {
    lat: 35.6916182,
    lng: 139.7691696,
  };

  useEffect(() => {
    dispatch(mapActions.getAll());
    initUserLocation();
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyDown, true);
  }, []);

  useEffect(() => {}, [centerObj, searchAction, suppliersFilter]);

  const filterSuppliers = useSelector((state) => state.map.filter);
  useEffect(() => {
    if (
      searchValue &&
      filterSuppliers.items &&
      filterSuppliers.items.data.suppliers.length > 0
    ) {
      setSuppliersFilter(filterSuppliers.items.data.suppliers);
    } else {
      setSuppliersFilter([]);
    }
  }, [filterSuppliers, zoomIn]);
  useEffect(() => {}, [centerObj]);
  const locations = useSelector((state) => state.map.get);
  const handleFitler = (event) => {
    event.preventDefault();
    setSearchValue(event.target.value);
    setSearchAction(true);
    if (event.target.value) {
      dispatch(mapActions.filter(event.target.value));
    } else {
      setSearchValue("");
      setSearchAction(false);
      setSuppliersFilter([]);
      setIndex(-1);
    }
  };
  const handleFocusOut = (event) => {
    event.preventDefault();
    setSearchValue("");
    setSuppliersFilter([]);
    setSearchAction(false);
  };

  const closeInfoWindow = () => {
    setInfowindowOpen(false);
  };

  const focusToPosition = (item) => {
    setSearchValue("");
    setSuppliersFilter([]);
    setCenterObj({
      lat: item.latitude,
      lng: item.longtitude,
    });
    setZoomIn(20);
    setTimeout(() => {
      setCenterObj(null);
      setZoomIn(null);
    }, 1000);
  };

  const [userLocation, setUserLocation] = useState(null);

  const initUserLocation = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          // update the value of userlocation variable
          setCenterObj({
            lat: latitude,
            lng: longitude,
          });
          setTimeout(() => {
            setCenterObj(null);
            setZoomIn(null);
          }, 1000);
          setUserLocation({ latitude, longitude });
        },
        // if there was an error getting the users location
        (error) => {
          toast.error("位置情報へのアクセスの許可が必要です", {
            position: "top-right",
          });
        }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getUserLocation = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          // update the value of userlocation variable
          setUserLocation({ latitude, longitude });
          let url =
            "https://www.google.com/maps/dir/?api=1&origin=" +
            latitude +
            "," +
            longitude +
            "&destination=" +
            lat +
            "," +
            long +
            "&travelmode=driving";

          window.open(url, "_blank");
        },
        // if there was an error getting the users location
        (error) => {
          toast.error("位置情報へのアクセスの許可が必要です", {
            position: "top-right",
          });
        }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  $(function () {
    // $("#target1").on("keyup", function (e) {
    //   e.preventDefault();
    //   e.isPropagationStopped();
    //   if (e.keyCode == 38) {
    //     console.log(e.keyCode);
    //   }
    // });
    // $("#target1").scrollIntoView();
    if ($("#locationsList").children[1]) {
      $("#locationsList").children[1].style.backgroundColor = "red";
    }
  });

  const handleKeyPress = (event) => {
    let total = document.getElementById("locationsList")?.children?.length;
    if (event.keyCode == 40) {
      if (total > 12) {
        document
          .getElementById("locationsList")
          .scrollBy({ top: 25, behavior: "smooth" });
      } else {
        document
          .getElementById("locationsList")
          .scrollBy({ top: 15, behavior: "smooth" });
      }

      // Not deleted, needed when request
      document.getElementById("locationsList").children[
        index + 1
      ].style.backgroundColor = "#cccccc";
      for (let i = 0; i < total - 1; i++) {
        if (i == index + 1) {
          break;
        }
        document.getElementById("locationsList").children[
          i
        ].style.backgroundColor = "white";
      }
      if (index < total - 1) {
        setIndex(index + 1);
      }
    } else if (event.keyCode == 38) {
      // Not deleted, needed when request
      if (index == -1) {
        document
          .getElementById("locationsList")
          .scrollBy({ top: 200, behavior: "smooth" });
        document.getElementById("locationsList").children[
          total - 1
        ].style.backgroundColor = "#cccccc";
        for (let i = total - 2; i >= 0; i--) {
          document.getElementById("locationsList").children[
            i
          ].style.backgroundColor = "white";
        }
        setIndex(total - 2);
      } else {
        if (total > 12) {
          document
            .getElementById("locationsList")
            .scrollBy({ top: -25, behavior: "smooth" });
        } else {
          document
            .getElementById("locationsList")
            .scrollBy({ top: -15, behavior: "smooth" });
        }
        document.getElementById("locationsList").children[
          index - 1
        ].style.backgroundColor = "#cccccc";
        for (let i = total - 1; i >= 1; i--) {
          if (i == index - 1) {
            break;
          }
          document.getElementById("locationsList").children[
            i
          ].style.backgroundColor = "white";
        }
        setIndex(index - 1);
      }
    }
  };

  const handleKeyDialog = (event) => {
    let total = document.getElementById("locationsList")?.children?.length;
    if (event.keyCode == 40) {
      document
        .getElementById("locationsList")
        .scrollBy({ top: 20, behavior: "smooth" });
      document
        .getElementById("locationsList")
        .scrollBy({ top: -20, behavior: "smooth" });
    }
  };

  const handleChangePinColor = (event) => {
    setCurrentPinColor(event.target.value);
    const supData = {};
    supData.id = currentSupplierId;
    supData.pinColor = event.target.value;
    dispatch(mapActions.update(supData));
    dispatch(mapActions.getAll());
  };

  const caculatePageNumber = (supId) => {
    const locationsCopy = [...locations.items.data];
    locationsCopy.sort((a, b) => b.id - a.id);
    let indexSup = locationsCopy.findIndex((sup) => sup.id === supId);
    let pageOfSup = Math.floor(indexSup / 10);
    setCurrentSupplierPage(pageOfSup);
    console.log(supId);
  };

  useEffect(() => {
    dispatch(mapActions.getAll());
  }, [currentPinColor]);

  return (
    <div>
      <PageHeader />
      <div className="mt-[10px] flex flex-row h-screen">
        <MenuBar />
        <div className="pl-[2%] 2xl:w-10/12 pr-[20px] xl:w-[80%] lg:w-[80%]">
          <div className="text-black text-xl font-medium ">仕入先管理</div>
          <div className="xl:w-full lg:w-full h-px bg-zinc-300 mt-[8px] 2xl:w-[98%]" />
          <div className="relative flex mt-[25px] text-sm font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex flex-wrap -mb-px">
              <SupplierManagementTab></SupplierManagementTab>
            </ul>
            <input
              onChange={handleFitler}
              value={searchValue}
              // onBlur={handleFocusOut}
              placeholder="キーワードを入力…"
              className="p-2 absolute top-[-20px] right-[17px] w-[30%] h-[39px] bg-white border border-zinc-500"
              onKeyUp={(e) => handleKeyPress(e)}
              id="target1"
            />
            <button className="absolute top-[-10px] right-[25px] w-[61px] h-[18px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold">
              検索
            </button>

            {suppliersFilter.length > 0 && (
              <div
                id="locationsList"
                className="p-2 absolute top-[20px] right-[15px] w-[30%] h-fit max-h-[250px] bg-white border border-zinc-500 z-10 overflow-y-auto touch-pan-y flex flex-col"
              >
                {filterSuppliers.items &&
                  filterSuppliers.items.data.suppliers.length > 0 &&
                  suppliersFilter.map((item) => {
                    return (
                      <div
                        className="flex w-full min-h-[40px] max-h-[40px] !h-[40px] cursor-pointer hover:bg-gray-300 active:bg-gray-300 items-center"
                        onClick={() => {
                          focusToPosition(item);
                          setSearchAction(false);
                        }}
                        key={item.id}
                      >
                        <div className="flex h-full w-2/6 text-left items-center pr-[5px]">
                          <Tooltip
                            title={item.name}
                            arrow
                            placement="bottom-start"
                          >
                            <span className="truncate">{item.name}</span>
                          </Tooltip>
                        </div>
                        <div className="flex h-full w-4/6 text-nowrap text-left truncate items-center">
                          <Tooltip
                            title={item.address_detail}
                            arrow
                            placement="bottom-start"
                          >
                            <span className="w-full truncate">
                              {item.address_detail}
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            {suppliersFilter.length == 0 && searchAction === true && (
              <div className="p-2 absolute top-[20px] right-[15px] w-[30%] h-fit max-h-[250px] bg-white border border-zinc-500 z-10 overflow-y-auto touch-pan-y flex flex-col">
                <div className="flex w-full min-h-[40px] max-h-[40px] !h-[40px] items-center">
                  一致する結果がありません
                </div>
              </div>
            )}
          </div>
          <div className="pb-[3%] w-[99%] h-[100%] relative 2xl:w-full xl:w-full lg:w-full">
            <APIProvider apiKey={API_KEY} language={MAP_LANGUAGE}>
              <Map
                style={{ width: "99%", height: "80%" }}
                defaultCenter={center}
                defaultZoom={14}
                zoom={zoomIn}
                center={centerObj}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
                mapId={"befa52f039122016"}
                // ref={markerRef}
              >
                {locations.items && locations.items.data.length > 0
                  ? locations.items.data.map((location) => {
                      if (
                        location.latitude !== null &&
                        location.longtitude !== null
                      ) {
                        return (
                          <div key={location.id}>
                            <AdvancedMarker
                              // ref={markerRef}
                              position={{
                                lat: location.latitude,
                                lng: location.longtitude,
                              }}
                              onClick={() => {
                                setInfowindowOpen(true);
                                setCurrentSupplierId(location.id);
                                setLat(location.latitude);
                                setLong(location.longtitude);
                                setName(location.name);
                                setAddress(location.address_detail);
                                setPhoneNumber(location.company_phone_number);
                                setDetail(location.detail);
                                setImageUrl(location.image_url);
                                setCurrentPinColor(location.pin_color);
                                caculatePageNumber(location.id);
                              }}
                              title={location.name}
                            >
                              <Pin
                                background={
                                  location.pin_color === 1
                                    ? "#ca5040"
                                    : location.pin_color === 2
                                    ? "#009d57"
                                    : "#f4eb37"
                                }
                                glyphColor={
                                  location.pin_color === 1
                                    ? "#ca5040"
                                    : location.pin_color === 2
                                    ? "#009d57"
                                    : "#f4eb37"
                                }
                              ></Pin>
                            </AdvancedMarker>
                          </div>
                        );
                      }
                    })
                  : ""}
              </Map>
            </APIProvider>
            {infowindowOpen && (
              <div className="w-[33%] h-[80%] absolute z-50 left-[0px] top-[0px] flex flex-col">
                <div className="h-[10%] w-full bg-[#ca5040] flex items-center pl-[5%] pr-[5%]">
                  <img
                    className="w-[17px] h-[17px] cursor-pointer"
                    src={leftlinearrow}
                    onClick={closeInfoWindow}
                  ></img>
                  <Tooltip title={name} arrow placement="bottom-start">
                    <span className="w-[80%] ml-[5%] truncate text-white">
                      {name}
                    </span>
                  </Tooltip>
                  <img
                    onClick={getUserLocation}
                    className="w-[25px] cursor-pointer"
                    src={direction}
                  ></img>
                </div>
                <div className="w-full h-[70%] flex flex-col bg-white pl-[20px] pt-[20px]">
                  <span className="text-sm text-[#9e9e9e]">名称</span>
                  <Tooltip title={name} arrow placement="bottom-start">
                    <span className="mt-[10px] truncate">{name}</span>
                  </Tooltip>
                  <span className="mt-[20px] text-sm text-[#9e9e9e]">住所</span>
                  <Tooltip title={address} arrow placement="bottom-start">
                    <span className=" truncate ">{address}</span>
                  </Tooltip>
                  <div className="xl:w-full lg:w-full h-px bg-zinc-300 mt-[20px]"></div>
                  <span className="mt-[40px] text-sm text-[#9e9e9e]">
                    Google マップからの詳細情報
                  </span>
                  <Tooltip title={detail} arrow placement="bottom-start">
                    <span className="truncate">{detail}</span>
                  </Tooltip>
                  <span className="mt-[10px]">
                    +12{" "}
                    {phoneNumber?.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3")}
                  </span>
                  <label
                    onClick={getUserLocation}
                    href=""
                    className=" mt-[20px] text-[#6792ef] cursor-pointer"
                  >
                    Googleマップで見る
                  </label>
                  <div className=" mt-[20px]">
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        className="font-medium"
                      >
                        PINの色を選択
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        // defaultValue={currentPinColor}
                        value={currentPinColor}
                        onChange={handleChangePinColor}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="赤"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="黄"
                          className="ml-[20px]"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="緑"
                          className="ml-[20px]"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <NavLink
                    to={{
                      pathname: `/supplier-management-list/${currentSupplierId}`,
                    }}
                    state={{ page: currentSupplierPage }}
                    className="bg-white text-xl mt-[20px] text-[#6792ef] cursor-pointer font-bold"
                  >
                    詳細を見る
                  </NavLink>
                </div>

                {/* <img
                  src={imageUrl}
                  className="h-[30%] w-full bg-white"
                  alt="You have not set an image for the supplier"
                /> */}
                <div className="h-[20%] w-full bg-white"></div>
                {/* <div className="bg-white w-full h-[9%]"></div> */}
              </div>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
