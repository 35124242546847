import React from "react";
import PageHeader from "../../../components/PageHeader";
import MenuBar from "../../../components/MenuBar";
import Moment from "moment";
import { notificationActions } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
import $ from "jquery";
import DialogNotiDetail from "./DialogNotiDetail";
import DialogNotiRegistration from "./DialogNotiRegistration";

export default function Notifications() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = React.useState(0);
  const notifications = useSelector((state) => state.notification.getAll);
  const [currentLimit, setCurrentLimit] = React.useState(10);
  const [modalDetailOpen, setModalDetailOpen] = React.useState(false);
  const [modalRegistOpen, setModalRegistOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [notiId, setNotiId] = React.useState();

  var body = {
    number_of_records_per_page: 10,
    page_number: 1,
  };

  const onInit = () => {
    dispatch(notificationActions.getAll(body));
  };

  React.useEffect(() => {
    onInit();
  }, []);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    var body = {
      number_of_records_per_page: currentLimit,
      page_number: event.selected + 1,
    };
    dispatch(notificationActions.getAll(body));
  };

  const onChangeLimit = (event) => {
    setCurrentLimit(event.target.value);
    setCurrentPage(0);
    var body = {
      number_of_records_per_page: event.target.value,
      page_number: 1,
    };
    dispatch(notificationActions.getAll(body));
  };

  const optionLimitPage = [
    { value: "10", label: "10" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "1000", label: "1000" },
  ];

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    navigate("/notification-register");
  };

  const publish = async (e, notiId) => {
    var body = {
      number_of_records_per_page: currentLimit,
      page_number: currentPage + 1,
    };
    e.preventDefault();
    var data = {
      id: notiId,
    };
    await dispatch(notificationActions.publish(data));
    dispatch(notificationActions.getAll(body));
  };

  $(function () {
    $("select").each(function () {
      this.style.setProperty("color", "#000", "important");
    });
    $("option").each(function () {
      this.style.setProperty("color", "#000", "important");
    });
  });

  const handleCloseRegistDialog = () => {
    onInit();
    setModalRegistOpen(false);
    setIsOpen(false);
  };

  const handleCloseDetailDialog = () => {
    onInit();
    setModalDetailOpen(false);
    setIsOpen(false);
  };

  const handleOpenDetail = (id) => {
    setNotiId(id);
    setModalDetailOpen(true);
    setIsOpen(true);
  };

  return (
    <div>
      <PageHeader />
      <div className="mt-[10px] flex flex-row h-screen">
        <MenuBar />
        <div className="pl-[2%] lg:w-[85%] xl:w-[85%] 2xl:w-[85%] pr-[21px] ">
          <div className="text-black text-xl font-medium mt-[20px]">
            配信済みお知らせ一覧{" "}
          </div>
          <div className="w-[80%] h-[2px] bg-zinc-300 mt-[20px] 2xl:w-full xl:w-full lg:w-full" />
          <div className="flex justify-left mt-[2px] 2xl:w-full xl:w-full lg:w-full">
            <button
              onClick={() => {
                setModalRegistOpen(true);
              }}
              className="bg-blue-900 w-[100px] h-[30px] rounded-3xl text-white text-[15px] font-normal text-center mt-[20px]"
            >
              新規作成
            </button>
          </div>
          <div className="flex flex-col h-[40px] mt-[20px] 2xl:w-full xl:w-full lg:w-full bg-[#f0ecec] items-center justify-center ">
            <div className="flex relative w-full flex text-[17px] h-[20px] font-normal items-center">
              <div className=" w-[15%] 2xl:w pl-[30px]">日付</div>
              <div className=" w-[30%]">内容 ※30文字まで</div>
              <div className="w-[20%]">公開設定</div>
              <div className=" w-[25%">リンク</div>
            </div>
            {/* <div className="w-[80%] h-[4px] bg-zinc-300 mt-[8px] 2xl:w-full xl:w-full lg:w-full" /> */}
          </div>

          <div
            className={classNames(
              "flex flex-col w-[80%] 2xl:max-h-[541px] xl:max-h-[440px] h-[541px] lg:max-h-[400px] 2xl:w-full xl:w-full lg:w-full",
              {
                "overflow-y-auto":
                  notifications.items &&
                  notifications?.items?.notis?.length > 0,
              }
            )}
            // key={notifications?.items?.notis}
          >
            {notifications.items && notifications?.items?.notis?.length > 0 ? (
              notifications.items.notis.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <div
                      className="relative w-full flex text-[17px] h-[45px] font-normal cursor-pointer items-center pl-[10px]"
                      onClick={() => handleOpenDetail(item.id)}
                    >
                      <span className="h-[25px] w-[14%] truncate">
                        {item.created_at
                          ? Moment(item.created_at).format("YYYY/MM/DD")
                          : ""}{" "}
                      </span>
                      <span className="w-[30%] truncate 2xl:pl-[10px]">
                        {" "}
                        <Tooltip title={item.notification_contents} arrow>
                          <span>
                            {item.notification_contents.length <= 30
                              ? item.notification_contents
                              : item.notification_contents.substring(0, 30) +
                                "..."}
                          </span>
                        </Tooltip>
                      </span>
                      <div className="w-[20%] flex 2xl:pl-[10px] xl:pl-[5px] lg:pl-[5px]">
                        {item.is_public == 0 && (
                          <label className="w-full text-[#4868cf] rounded-md text-[15px] font-normal">
                            非公開
                          </label>
                        )}
                        {item.is_public == 1 && (
                          <label className="w-full text-red-600 text-[15px] font-normal ">
                            公開
                          </label>
                        )}
                      </div>
                      <span className="w-[25%] truncate 2xl:pl-[15px] xl:pl-[15px] lg:pl-[10px] cursor-pointer">
                        <Tooltip title={item.link} arrow>
                          <span>
                            {item.link.length <= 20
                              ? item.link
                              : item.link.substring(0, 20) + "..."}
                          </span>
                        </Tooltip>
                      </span>
                      <div className="w-[10%] flex justify-end">
                        <button
                          onClick={() => handleOpenDetail(item.id)}
                          className="w-[50%] text-[#1c4587] rounded-md text-[15px] font-normal text-center"
                        >
                          詳細
                        </button>
                      </div>
                    </div>
                    <div className="w-[80%] min-h-[1px] bg-zinc-300 mt-[8px] 2xl:w-full xl:w-full lg:w-full" />
                    {/* <NotificationsDetail/> */}
                  </React.Fragment>
                );
              })
            ) : (
              <>
                <div className="flex w-[80%] 2xl:w-full h-full justify-center items-center">
                  {notifications.loading ? (
                    <div className="text-black text-[15px] font-normal ">
                      <CircularProgress color="secondary" />
                    </div>
                  ) : (
                    <div className="top-[400px] text-black text-[15px] font-normal ml-[10px]">
                      一致する結果はありません
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <DialogNotiDetail
            open={modalDetailOpen}
            onClose={() => handleCloseDetailDialog()}
            notiId={notiId}
          />
          <DialogNotiRegistration
            open={modalRegistOpen}
            onClose={() => handleCloseRegistDialog()}
          />

          {notifications.items && notifications?.items?.notis?.length > 0 && (
            <div className="relative flex justify-between mt-[17px] mr-[5px]">
              <div className="flex items-center">
                <div className="w-[38px] h-3 text-zinc-500 text-[11px] font-medium  leading-3">
                  表示数
                </div>
                <select
                  defaultValue={currentLimit}
                  className="w-[69px] h-[23px] bg-white rounded-sm border border-stone-300 ml-[5px] mr-[5px] focus:ring-blue-500 focus:border-blue-500 text-center text-sm cursor-pointer"
                  onChange={(e) => onChangeLimit(e)}
                >
                  {optionLimitPage.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
                <div className="w-44 h-3 text-zinc-500 text-[11px] font-medium  leading-3">
                  全 {notifications.items.totalRows} 件中 /{" "}
                  {currentPage * currentLimit + 1}-
                  {(currentPage + 1) * currentLimit >
                    notifications.items.totalRows &&
                  (notifications.items.totalPages == 1 ||
                    currentPage + 1 == notifications.items.totalPages)
                    ? notifications.items.totalRows
                    : (currentPage + 1) * currentLimit}
                  件目を表示
                </div>
              </div>
              <ReactPaginate
                breakClassName="pb-[8px] mr-[5px]"
                containerClassName=" flex items-center justify-center"
                previousLabel={
                  <span className="w-3 h-3 flex items-center justify-center rounded-md">
                    <BsArrowLeft />
                  </span>
                }
                nextLabel={
                  <span className="w-3 h-3 flex items-center justify-center rounded-md">
                    <BsArrowRight />
                  </span>
                }
                pageClassName="block border-solid hover:rounded-full hover:bg-gray-100 w-5 h-5 text-center text-zinc-800 text-[12px] font-bold  leading-3 flex items-center justify-center rounded-md mx-[3px]"
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                marginPagesDisplayed={0}
                activeClassName="block border-solid rounded-md rounded-full bg-gray-100 w-5 h-5"
                pageCount={
                  Math.ceil(notifications.items?.totalPages) > 0
                    ? Math.ceil(notifications.items?.totalPages)
                    : currentPage + 1
                }
                breakLabel="..."
                renderOnZeroPageCount={null}
                forcePage={currentPage}
                pageLinkClassName="text-[12px]"
              ></ReactPaginate>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
