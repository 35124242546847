import React from "react";
import Moment from "moment";
import { userActions } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getLocalStorage } from "../../../utils/helper/localStorage";
import { LOCAL_STORAGE_USER } from "../../../../constants";
import { CircularProgress, Dialog, Tooltip } from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import hide from "../../../assets/hide.png";
import show from "../../../assets/show.png";
import { userConstants } from "../../../constants";
import $ from "jquery";

function UserDetail({
  open,
  onClose,
  id,
  onOpenConfirmDelete,
  onUpdate,
  onReject,
  onApprove,
}) {
  const [isEdit, setIsEdit] = React.useState(false);
  const dispatch = useDispatch();
  const userLogin = getLocalStorage(LOCAL_STORAGE_USER);
  const user = useSelector((state) => state.user.get);
  const [isError, setIsError] = React.useState(false);
  const update = useSelector((state) => state.user.update);
  const fields = [
    "admin_id",
    "age",
    "company_working_at_name",
    "company_working_at_phone_number",
    "email",
    "gender",
    "approve_status",
    "name",
    "created_at",
    "created_at",
    "id",
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    setValue,
  } = useForm();

  const getUser = async () => {
    await dispatch(userActions.get(id));
  };

  React.useEffect(() => {
    if (open) {
      getUser();
    }
  }, [open]);

  React.useEffect(() => {
    if (user.item) {
      fields.forEach((field) => {
        if (field == "gender") {
          setValue(field, "" + user.item?.data[0][field]);
        } else {
          setValue(field, user.item?.data[0][field]);
        }
      });
    }
  }, [user]);

  const handleEdit = async (data) => {
    if (isEdit) {
      data.password_hash =
        data.password_hash != ""
          ? data.password_hash
          : user.item.data[0].password_hash;
      data.id = user.item.data[0].id;
      data.admin_id = user.item.data[0].admin_id;
      data.approve_status = user.item.data[0].approve_status;

      await dispatch(userActions.update(data));
    }
  };

  React.useEffect(() => {
    if (update.item) {
      dispatch({ type: userConstants.UPDATE_SUCCESS, payload: null });
      onUpdate();
      setIsEdit(!isEdit);
      setValue("password_hash", "");
    } else if (update.error) {
      if (update.error.email) {
        const formError = {
          type: "server",
          message: update.error.email.message,
        };
        setError("email", formError);
      }
    }
  }, [update]);

  const handleDelete = () => {
    onOpenConfirmDelete();
  };

  const handleApprove = async () => {
    await dispatch(userActions.approve(user.item.data[0].id));
    onApprove();
  };

  const handleReject = async () => {
    await dispatch(userActions.reject(user.item.data[0].id));
    onReject();
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose();
    setIsEdit(false);
    reset();
  };

  $(function () {
    $("#age").each(function () {
      this.style.setProperty("color", "#000", "important");
    });
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <form>
        <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none text-sm">
          <ClickAwayListener onClickAway={handleClose}>
            <div className="relative flex flex-col w-[870px] h-[690px] bg-white rounded-[10px] overflow-y-auto">
              {!user.item ? (
                <div className="text-black text-[15px] font-normal ml-[50%] mt-[35%]">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  {user.item?.data[0].approve_status != 1 && (
                    <>
                      {isEdit ? (
                        <button
                          type="button"
                          onClick={handleSubmit(handleEdit)}
                          className="absolute left-[706px] top-[27px] w-[61px] h-[18px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold "
                        >
                          保存
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsEdit(true);
                          }}
                          className="absolute left-[706px] top-[27px] w-[61px] h-[18px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold "
                        >
                          編集
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => handleDelete()}
                        className=" absolute left-[784px] top-[27px] w-[61px] h-[18px] bg-white rounded-[50px] border border-red-600 text-center text-red-600 text-xs font-bold "
                      >
                        削除
                      </button>
                    </>
                  )}
                  <div className="ml-[22px] mt-[28px] text-black text-xl font-bold ">
                    ユーザー詳細
                  </div>
                  <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[30px]" />
                  <div className="flex flex-row ml-[22px] mt-[10px]">
                    <span className="text-black text-sm font-normal ">
                      ユーザー番号
                    </span>
                    <div className="text-black text-sm font-medium font-['YuGothic'] ml-[60px]">
                      {/* {("" + user.item?.data[0].admin_id).length < 5
                ? ("10000" + user.item?.data[0].admin_id).slice(-5)
                : user.item?.data[0].admin_id} */}
                      {"100" + user.item?.data[0].id}
                    </div>
                  </div>
                  <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[11px]" />

                  <div className="flex flex-row ml-[22px] mt-[8px] items-center">
                    <span className="text-black text-sm font-normal ">
                      ユーザー名
                    </span>
                    <input
                      readOnly={!isEdit}
                      name="name"
                      className="p-2 w-[670px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[70px]"
                      {...register("name", {
                        required: "ユーザー名を入力してください",
                      })}
                      contentEditable={false}
                      placeholder="東洋　太郎"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "東洋　太郎")}
                    />
                  </div>
                  {errors.name && (
                    <div className="ml-[163px] w-[825px] text-red-500 mb-[15px] h-px">
                      <span>{errors.name.message}</span>
                    </div>
                  )}

                  <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[8px] pt-[1px]" />
                  <div className="flex flex-row ml-[22px] mt-[10px] items-center">
                    <span className="text-black text-sm font-normal ">
                      {isEdit ? "新パスワード" : "パスワード"}
                    </span>
                    {isEdit ? (
                      <>
                        <input
                          readOnly={!isEdit}
                          name="password_hash"
                          type="password"
                          className="p-2 w-[291px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[57px]"
                          {...register("password_hash", {
                            pattern: {
                              value:
                                /^(?=.*?[0-9])(?=.*?[A-Za-z]).{8,}$/,
                              message:
                                "半角英数字と記号を組み合わせた8文字以上のパスワードを入力してください",
                            },
                          })}
                          contentEditable={false}
                          placeholder="半角英数字、記号の組み合わせ8文字以上"
                          onFocus={(e) => (e.target.placeholder = "")}
                          onBlur={(e) =>
                            (e.target.placeholder =
                              "半角英数字、記号の組み合わせ8文字以上")
                          }
                        />
                      </>
                    ) : (
                      <span className="p-2 w-[291px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[70px]">
                        ******************
                      </span>
                    )}
                  </div>
                  {errors.password_hash && (
                    <div className="ml-[163px] w-[825px] text-red-500 mb-[15px] h-px">
                      <span>{errors.password_hash.message}</span>
                    </div>
                  )}

                  <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[8px] pt-[1px]" />
                  <div className="flex flex-row ml-[22px] mt-[10px] items-center">
                    <span className="text-black text-sm font-normal ">
                      在籍企業名
                    </span>
                    <input
                      readOnly={!isEdit}
                      name="company_working_at_name"
                      className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[70px] text-sm"
                      {...register("company_working_at_name", {
                        required: "在籍企業名を入力してください",
                      })}
                      contentEditable={false}
                      placeholder="東洋舗材株式会社"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "東洋舗材株式会社")
                      }
                    />
                  </div>
                  {errors.company_working_at_name && (
                    <div className="ml-[163px] w-[825px] text-red-500 mb-[15px] h-px">
                      <span>{errors.company_working_at_name.message}</span>
                    </div>
                  )}

                  <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[8px] pt-[1px]" />
                  <div className="flex flex-row ml-[22px] mt-[10px] items-center">
                    <span className="text-black text-sm font-normal ">
                      在籍企業電話番号
                    </span>
                    <input
                      readOnly={!isEdit}
                      name="company_working_at_phone_number"
                      className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[30px] text-sm"
                      {...register("company_working_at_phone_number", {
                        pattern: {
                          value: /^[0-9０-９]*$/,
                          message: "有効な企業電話番号（英数字のみ）を入力してください",
                        },
                      })}
                      contentEditable={false}
                      placeholder="080-1234-5678"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "080-1234-5678")}
                    />
                  </div>
                  {errors.company_working_at_phone_number && (
                    <div className="ml-[163px] w-[825px] text-red-500 mb-[15px] h-px">
                      <span>
                        {errors.company_working_at_phone_number.message}
                      </span>
                    </div>
                  )}

                  <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[8px] pt-[1px]" />
                  <div className="flex flex-row ml-[22px] mt-[10px] items-center">
                    <span className="text-black text-sm font-normal ">
                      メールアドレス
                    </span>
                    <input
                      readOnly={!isEdit}
                      name="email"
                      className="p-2 w-[666px] h-[35px] bg-white rounded-[10px] border border-stone-300 ml-[43px] text-sm"
                      {...register("email", {
                        required: "メールアドレスを入力してください",
                        pattern: {
                          value:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "有効なメールアドレスを入力してください",
                        },
                      })}
                      contentEditable={false}
                      placeholder="◯◯◯◯@gmai.com"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "◯◯◯◯@gmai.com")}
                    />
                  </div>
                  {errors.email && (
                    <div className="ml-[163px] w-[825px] text-red-500 mb-[15px] h-px">
                      <span>{errors.email.message}</span>
                    </div>
                  )}

                  <div className="w-[815px] h-px bg-zinc-300 ml-[22px] mt-[8px] pt-[1px]" />
                  <div className="flex flex-row ml-[22px] mt-[24px]">
                    <span className="text-black text-sm font-normal ">
                      性別（任意）
                    </span>
                    <input
                      disabled={!isEdit}
                      id="default-radio-1"
                      type="radio"
                      value="1"
                      name="gender"
                      {...register("gender")}
                      class="ml-[55px] w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    />
                    <label
                      for="default-radio-1"
                      class="ms-2 text-sm font-medium mr-4"
                    >
                      男性
                    </label>
                    <input
                      disabled={!isEdit}
                      id="default-radio-2"
                      type="radio"
                      value="2"
                      name="gender"
                      {...register("gender")}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    />
                    <label
                      for="default-radio-2"
                      class="ms-2 text-sm font-medium mr-4"
                    >
                      女性
                    </label>
                    <input
                      disabled={!isEdit}
                      id="default-radio-3"
                      type="radio"
                      value="3"
                      name="gender"
                      {...register("gender")}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    />
                    <label
                      for="default-radio-3"
                      class="ms-2 text-sm font-medium mr-4"
                    >
                      その他
                    </label>
                    <input
                      disabled={!isEdit}
                      id="default-radio-4"
                      type="radio"
                      value="4"
                      name="gender"
                      {...register("gender")}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                    />
                    <label
                      for="default-radio-4"
                      class="ms-2 text-sm font-medium mr-4"
                    >
                      回答しない
                    </label>
                  </div>
                  {errors.gender && (
                    <div className="ml-[163px] w-[825px] text-red-500 mb-[15px] h-px">
                      <span>{errors.gender.message}</span>
                    </div>
                  )}

                  <div className="flex flex-row ml-[22px] mt-[15px] items-center">
                    <span className="text-black text-sm font-normal ">
                      年齢（任意）
                    </span>
                    <select
                      id="age"
                      name="age"
                      {...register("age")}
                      className="p-1 w-40 h-8 bg-white rounded-[10px] border border-stone-300 ml-[55px] text-black text-sm"
                    >
                      <option
                        disabled={!isEdit ? "disabled" : ""}
                        className="text-gray-900 dark:text-gray-300 !text-black"
                        value="0"
                        selected
                      >
                        未回答
                      </option>
                      <option
                        disabled={!isEdit ? "disabled" : ""}
                        className="text-black"
                        value="1"
                      >
                        10-19歳
                      </option>
                      <option
                        disabled={!isEdit ? "disabled" : ""}
                        className="text-black"
                        value="2"
                      >
                        20-29歳
                      </option>
                      <option
                        disabled={!isEdit ? "disabled" : ""}
                        className="text-black"
                        value="3"
                      >
                        30-39歳
                      </option>
                      <option
                        disabled={!isEdit ? "disabled" : ""}
                        className="text-black"
                        value="4"
                      >
                        40-49歳
                      </option>
                      <option
                        disabled={!isEdit ? "disabled" : ""}
                        className="text-black"
                        value="5"
                      >
                        50-59歳
                      </option>
                      <option
                        disabled={!isEdit ? "disabled" : ""}
                        className="text-black"
                        value="6"
                      >
                        60-69歳
                      </option>
                      <option
                        disabled={!isEdit ? "disabled" : ""}
                        className="text-black"
                        value="7"
                      >
                        70-79歳
                      </option>
                    </select>
                  </div>
                  {errors.age && (
                    <div className="ml-[163px] w-[825px] text-red-500 mb-[15px] h-px">
                      <span>{errors.name.age}</span>
                    </div>
                  )}
                  <div className="flex flex-row ml-[22px] mt-[18px]">
                    <span className="text-black text-sm font-normal ">
                      アカウント登録日
                    </span>
                    <span className="text-black text-sm font-normal ml-[30px]">
                      {user.item?.data[0].created_at
                        ? Moment(user.item.data[0].created_at).format(
                            "YYYY/MM/DD"
                          )
                        : ""}
                    </span>
                  </div>
                  <div className="flex flex-row ml-[22px] mt-[20px]">
                    <span className="text-black text-sm font-normal ">
                      アカウント登録者ID
                    </span>
                    {/* <Tooltip title={userLogin.name} arrow>
                  <span className="text-black text-sm font-normal ml-[17px]">
                    {userLogin.name.length <= 20
                      ? userLogin.name
                      : userLogin.name.substring(0, 20) + "..."}
                  </span>
                </Tooltip> */}
                    <Tooltip title={"100" + userLogin?.id} arrow>
                      <span className="text-black text-sm font-normal ml-[17px]">
                        {"100" + userLogin?.id}
                      </span>
                    </Tooltip>
                  </div>
                  <div className="flex flex-row ml-[22px] mt-[15px]">
                    <span className="text-black text-sm font-normal ">
                      ステータス
                    </span>
                    {user.item?.data[0].is_deleted == 0 && (
                      <span className="text-black text-sm font-normal ml-[73px]">
                        アクティブ
                      </span>
                    )}
                    {user.item?.data[0].is_deleted == 1 && (
                      <span className="text-black text-sm font-normal ml-[73px]">
                        退会済み
                      </span>
                    )}
                  </div>
                  <div className="w-full h-[39px] mt-[23px] flex flex-row justify-center">
                    <button
                      type="button"
                      className="w-[193px] h-[39px] bg-white/opacity-60 rounded-[50px] border border-black/opacity-20 text-stone-400 text-xl font-bold focus:outline-none focus:ring-2 focus:border-transparent hover:bg-slate-100"
                      onClick={() => {
                        onClose();
                        reset();
                        setIsEdit(false);
                      }}
                    >
                      戻る
                    </button>
                    {user.item?.data[0].approve_status == 1 && (
                      <>
                        <button
                          type="button"
                          className="w-[193px] h-[39px] ml-[23px] mr-[17px] bg-blue-900  rounded-[50px] text-white text-xl font-bold "
                          onClick={() => handleApprove()}
                        >
                          承認
                        </button>
                        <button
                          type="button"
                          className=" w-[193px] h-[39px] bg-zinc-500 rounded-[50px] text-white text-xl font-bold "
                          onClick={() => handleReject()}
                        >
                          非承認
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </ClickAwayListener>
        </div>
      </form>
    </Dialog>
  );
}
export default UserDetail;
