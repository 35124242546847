export const categoryConstants = {
  GETBIG_REQUEST: 'CATEGORIES_GETBIG_REQUEST',
  GETBIG_SUCCESS: 'CATEGORIES_GETBIG_SUCCESS',
  GETBIG_FAILURE: 'CATEGORIES_GETBIG_FAILURE',

  GETMED_REQUEST: 'CATEGORIES_GETMED_REQUEST',
  GETMED_SUCCESS: 'CATEGORIES_GETMED_SUCCESS',
  GETMED_FAILURE: 'CATEGORIES_GETMED_FAILURE',

  GETDET_REQUEST: 'CATEGORIES_GETDET_REQUEST',
  GETDET_SUCCESS: 'CATEGORIES_GETDET_SUCCESS',
  GETDET_FAILURE: 'CATEGORIES_GETDET_FAILURE',
};
