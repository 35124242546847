import React from "react";
import MenuBar from "../../../components/MenuBar";
import PageHeader from "../../../components/PageHeader";
import UserListTab from "./UserListTab";
import TabUserApprove from "./TabUserApprove";
import TabUserUnApprove from "./TabUserUnApprove";

export default function UserManagementList() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isTabApprove, setIsTabApprove] = React.useState(true);
  const [isTabUnApprove, setIsTabUnApprove] = React.useState(false);

  const handleOpenModal = (open) => {
    setIsOpen(open);
  };

  const handleOpenApproveTab = () => {
    setIsTabApprove(true);
    setIsTabUnApprove(false);
  };

  const handleUnApproveTab = () => {
    setIsTabApprove(false);
    setIsTabUnApprove(true);
  };
  return (
    <div className="flex flex-col w-screen h-screen overflow-y-visible">
      <div
        className={isOpen ? "opacity-25 fixed inset-0 z-40 bg-black" : ""}
      ></div>
      <PageHeader />
      <div className="mt-[10px] flex flex-row 2xl:h-[90%] xl:h-[90%] lg:h-[90%] 2xl:w-screen xl:w-screen lg:w-screen">
        <MenuBar />
        <div className="pl-[2%] lg:w-[80%] xl:w-[80%] 2xl:w-[80%] h-[80%]">
          <div className="text-black text-xl font-medium ">ユーザー管理</div>
          <div className="w-[1170px] h-px bg-zinc-300 mt-[8px] 2xl:w-full" />
          <UserListTab
            onOpenApproveTab={() => handleOpenApproveTab()}
            onOpenUnApproveTab={() => handleUnApproveTab()}
          />
          {isTabApprove && (
            <TabUserApprove openModal={handleOpenModal}></TabUserApprove>
          )}
          {isTabUnApprove && (
            <TabUserUnApprove openModal={handleOpenModal}></TabUserUnApprove>
          )}
        </div>
      </div>
    </div>
  );
}
