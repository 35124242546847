import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "./src/components/container/DashboardLayout";
import MainLayout from "./src/components/container/MainLayout";
import NotFound from "./src/pages/common/NotFound";
import { Login, ReissuePassword } from "./src/pages/index";
import SupplierManagementMap from "./src/pages/admin/supplier/SupplierManagementMap";
import SupplierManagementMapV2 from "./src/pages/admin/supplier/SupplierManagementMapV2";
import SupplierManagementList from "./src/pages/admin/supplier/SupplierManagementList";
import SupplierRegistration from "./src/pages/admin/supplier/SupplierRegistration";
import SupplierDetail from "./src/pages/admin/supplier/SupplierDetail";
import RegistrationDialogSuccess from "./src/pages/admin/supplier/RegistrationDialogSuccess";
import UserManagementList from "./src/pages/admin/user/UserManagementList";
import Settings from "./src/pages/admin/settings/Settings";
import SupplierManagementFileList from "./src/pages/admin/supplier/SupplierManagementFileList";
import Notifications from "./src/pages/admin/settings/Notifications";
import NotificationsRegister from "./src/pages/admin/settings/NotificationsRegister";
import NotificationsDetail from "./src/pages/admin/settings/NotificationsDetail";

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "reissue-password", element: <ReissuePassword /> },
      { path: "supplier-management-map", element: <SupplierManagementMap /> },
      { path: "supplier-management-list/:id?", element: <SupplierManagementList /> },
      {
        path: "supplier-management-files",
        element: <SupplierManagementFileList />,
      },
      { path: "supplier-regist", element: <SupplierRegistration /> },
      { path: "userlist", element: <UserManagementList /> },
      { path: "smmap", element: <SupplierManagementMap /> },
      { path: "smregist", element: <SupplierRegistration /> },
      { path: "supdetail", element: <SupplierDetail /> },
      { path: "registrationdialog", element: <RegistrationDialogSuccess /> },
      { path: "settings", element: <Settings /> },
      { path: "notifications", element: <Notifications /> },
      { path: "notification-register", element: <NotificationsRegister /> },
      { path: "notification-detail/:id", element: <NotificationsDetail /> },
    ],
  },

  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "404", element: <NotFound /> },
      { path: "login", element: <Login /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
