import React from "react";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_USER, LOCAL_STORAGE_TOKEN } from "../../../constants";

export default function NotFound() {
  const navigate = useNavigate();

  const handleBack = (e) => {
    e.preventDefault();
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
    const user = localStorage.getItem(LOCAL_STORAGE_USER);
    if (!token || !user) {
      navigate("/login");
    } else {
      navigate("/userlist");
    }
  };

  return (
    <>
      <section className="w-screen h-screen bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
              404
            </h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
              見つかりませんでした。
            </p>
            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
              申し訳ありませんが、そのページは見つかりませんでした。{" "}
            </p>
            <button
              onClick={(e) => handleBack(e)}
              href="#"
              className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
            >
              ホームページに戻る
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
