import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import React from "react";
import weblogo from "../../../assets/weblogo.png";
import { userActions } from "../../../actions";
import axs from "../../../../http-common";
import { LinearProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { LOCAL_STORAGE_TOKEN } from "../../../../constants";

export default function index() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.user.login);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  React.useEffect(() => {
    if (login.token) {
      axs.defaults.headers.common.Authorization = `Bearer ${login.token}`;
      if (localStorage.getItem(LOCAL_STORAGE_TOKEN) != null) {
        navigate("/userlist");
      }
    } else if (login.error) {
      setError("入力した情報に誤りがあります");
    }
  }, [login]);

  React.useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/userlist");
    }
  }, []);

  const handleFormSubmit = async (data) => {
    setError("");
    await dispatch(userActions.login(data));
  };

  return (
    <div className="flex items-center justify-center w-screen h-screen 2xl:flex-col font-montserrat">
      <div className="bg-[#1A2847] relative w-full h-full flex justify-center content-center ">
        <form className="flex items-center justify-center flex w-screen h-screen flex-col">
          <img className="w-[251px] h-[123px]" src={weblogo} />
          <div className="w-[126px] h-fit">
            <div className="mt-[10px] mb-[48px] text-center text-white text-lg font-medium leading-[30px]">
              KENZAI LIFE
            </div>
          </div>
          <div className="flex flex-col w-fit">
            <label
              htmlFor="email"
              className="block text-white text-sm font-normal mb-[8px]"
            >
              ID
            </label>
            <input
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
              id="email"
              type="email"
              name="email"
              className="p-2 w-[315px] h-[30px] mb-[8px] rounded-[5px] border border-white bg-transparent text-white placeholder-white"
              placeholder="メールアドレス"
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "メールアドレス")}
              autoComplete="off"
            />
            {errors.email?.type === "required" && (
              <span className="text-red-500">
                メールアドレスを入力してください
              </span>
            )}
            {errors.email?.type === "pattern" && (
              <span className="text-red-500">メールアドレスが無効です</span>
            )}
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="password"
              className="block text-white text-sm font-normal mb-1"
            >
              パスワード
            </label>
            <input
              {...register("password", {
                required: "Password is required",
              })}
              id="password"
              type="password"
              className="p-2 w-[315px] h-[30px] rounded-[5px] border border-white bg-transparent text-white placeholder-white"
              placeholder="PASSWORD"
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "PASSWORD")}
              autoComplete="off"
            />
            {errors.password?.type === "required" && (
              <span className="text-red-500">パスワードを入力してください</span>
            )}
          </div>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          {login.loading && (
            <LinearProgress className="mt-[20px] mb-[20px] w-[100px] h-[2px] text-center" />
          )}
          <div className="text-center mt-[33px]">
            <span className="text-white text-sm font-medium">
              パスワードをお忘れの方は
              <br />
            </span>
            <a
              href="/reissue-password"
              className="text-white text-sm font-medium underline "
            >
              こちら
            </a>
            <span className="text-white text-sm font-medium">
              より再発行をしてください
            </span>
          </div>
          <button
            onClick={handleSubmit(handleFormSubmit)}
            className=" w-[261px] h-10 mt-[33px] bg-white text-black text-[15px] font-normal rounded-3xl flex items-center justify-center text-white text-[15px] font-normal hover:bg-sky-700 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300"
          >
            <div className="text-black text-[15px] font-bold ">ログイン</div>
          </button>
        </form>
      </div>
    </div>
  );
}
