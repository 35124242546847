export const mapConstants = {
  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  FILTER_REQUEST: 'FILTER_REQUEST',
  FILTER_SUCCESS: 'FILTER_SUCCESS',
  FILTER_FAILURE: 'FILTER_FAILURE',

  UPDATE_PIN_REQUEST: 'UPDATE_PIN_REQUEST',
  UPDATE_PIN_SUCCESS: 'UPDATE_PIN_SUCCESS',
  UPDATE_PIN_FAILURE: 'UPDATE_PIN_FAILURE',
};
