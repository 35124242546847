import React, { useRef, useImperativeHandle } from "react";
import PageHeader from "../../../components/PageHeader";
import MenuBar from "../../../components/MenuBar";
import { useForm } from "react-hook-form";
import { LOCAL_STORAGE_USER } from "../../../../constants";
import { notificationActions } from "../../../actions";
import DialogCompletedCreateNoti from "../../../components/DialogCompletedCreateNoti";
import DialogConfirmCreateNoti from "../../../components/DialogConfirmCreateNoti";
import DialogConfirmEditNoti from "../../../components/DialogConfirmEditNoti";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorage } from "../../../utils/helper/localStorage";
import Moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog } from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

export default function NotificationsDetail() {
  const dispatch = useDispatch();
  const userLogin = getLocalStorage(LOCAL_STORAGE_USER);
  const [messageSuccess, setMessageSuccess] = React.useState("");
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const noti = useSelector((state) => state.notification.getById);
  const formRef = useRef();

  const fields = [
    "id",
    "admin_id",
    "notification_contents",
    "link",
    "is_public",
    "created_at",
    "updated_at",
  ];
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm();

  const initData = async () => {
    if (id) {
      await dispatch(notificationActions.getById(id));
    }
  };

  React.useEffect(() => {
    initData();
  }, []);

  React.useEffect(() => {
    if (noti.item) {
      fields.forEach((field) => {
        if (field == "is_public") {
          setValue(field, "" + noti.item[field]);
        } else {
          setValue(field, noti.item[field]);
        }
      });
    }
  }, [noti]);

  const handleFormSubmit = async (data) => {
    data.admin_id = userLogin?.id;
    data.updated_at = Moment(new Date()).format("YYYY/MM/DD");
    data.id = noti.item.id;
    await dispatch(notificationActions.update(data));
    setSuccessDialogOpen(true);
    setConfirmDialogOpen(false);
    setMessageSuccess("1件のお知らせが\n登録されました");
    setIsOpen(true);
  };

  useImperativeHandle(formRef, () => ({
    submitForm() {
      handleSubmit(handleFormSubmit)();
    },
  }));

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose(false);
  };

  const handleOpenConfirmDialog = (e) => {
    e.preventDefault();
    setConfirmDialogOpen(true);
    setIsOpen(true);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(handleFormSubmit())}>
        <ClickAwayListener onClickAway={handleClose}>
          <div className="fixed inset-0">
            <div className="flex items-center justify-center min-h-screen ">
              <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"></div>
              <div className="w-[870px] h-[389px] bg-white rounded-lg overflow-hidden shadow-xl transform">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-bold text-gray-900">
                        お知らせ 編集
                      </h3>
                      <div className="flex flex-rows mt-8">
                        <div className="pr-10">
                          <label className="text-sm font-medium text-gray-700 whitespace-nowrap">
                            内容 ※30文字まで
                          </label>
                        </div>
                        {/* <input className="mt-1 w-[655px] h-[65px] border border-gray-300 rounded-md" /> */}
                        <input
                          type="text"
                          {...register("notification_contents", {
                            required: true,
                            maxLength: 30,
                          })}
                          className="mt-1 w-[655px] h-[65px] border border-gray-300 rounded-md"
                        />
                        {errors.notification_contents?.type === "required" && (
                          <span className="text-red-900 text-[18px]">
                            This field is required
                          </span>
                        )}
                        {errors.notification_contents?.type === "maxLength" && (
                          <span className="text-red-900 text-[18px]">
                            This field max length is 30
                          </span>
                        )}
                      </div>
                      <div className="w-[812px] h-px bg-zinc-300 mt-[10px]" />
                      <div className="flex flex-rows mt-2 items-center">
                        <div className="pr-16">
                          <label className="text-sm font-medium text-gray-700 whitespace-nowrap">
                            通常リンク
                          </label>
                        </div>
                        <div className="pl-6">
                          <input
                            {...register("link", { required: true })}
                            className="text-lg p-2 w-[655px] h-[30px] border border-gray-300 rounded-md"
                          />
                          {errors.link?.type === "required" && (
                            <span className="text-red-900 text-[18px]">
                              This field is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="w-[812px] h-px bg-zinc-300 mt-[10px]" />
                      <div className="mt-4 flex items-center">
                        <div className="pr-11">
                          <label className="text-sm font-medium text-gray-700">
                            公開設定
                          </label>
                        </div>

                        <div className="pl-14 flex items-center">
                          <input
                            {...register("is_public")}
                            id="public"
                            name="is_public"
                            value="1"
                            checked
                            type="radio"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor="public"
                            className="ml-3 text-sm font-medium text-gray-700"
                          >
                            公開する
                          </label>
                          <input
                            {...register("is_public")}
                            id="unpublic"
                            name="is_public"
                            type="radio"
                            value="0"
                            className="ml-6 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            htmlFor="unpublic"
                            className="ml-3 text-sm font-medium text-gray-700"
                          >
                            非公開にする
                          </label>
                        </div>
                      </div>
                      <div className="w-[812px] h-px bg-zinc-300 mt-[10px]" />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 flex flex-row-reverse items-center justify-center gap-10">
                  <button
                    type="button"
                    onClick={handleOpenConfirmDialog}
                    className="rounded-[50px] w-[260px] h-[39px] border shadow-sm px-4 py-1 bg-[#173E92] opacity-60 text-bold font-medium text-white hover:bg-blue-700 ml-3"
                  >
                    <span className="text-[20px]">お知らせを編集する</span>
                  </button>
                  <button
                    type="button"
                    onClick={handleClose}
                    className="rounded-[50px] w-[193px] h-[39px] border border-gray-300 shadow-sm px-4 py-2 bg-[#FFFFFF] opacity-60 text-bold font-medium text-gray-700 hover:bg-gray-50 mt-0 text-sm"
                  >
                    <Link to={`/notifications`} className="text-[20px]">
                      キャンセル
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </form>
      <DialogConfirmEditNoti
        open={confirmDialogOpen}
        message="お知らせを編集しますか？ \n※公開設定を「公開」にしている場合、\nお知らせはユーザに公開されます"
        onConfirm={() => formRef.current.submitForm()}
        onClose={() => {
          setConfirmDialogOpen(false);
          setIsOpen(false);
        }}
      />
      <DialogCompletedCreateNoti
        open={successDialogOpen}
        message="お知らせを編集しました"
        onClose={() => {
          setSuccessDialogOpen(false);
          setIsOpen(false);
          navigate("/notifications");
        }}
      />
    </Dialog>
  );
}
