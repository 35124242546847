export const notificationConstants = {
  GETALL_REQUEST: 'NOTIFICATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'NOTIFICATIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'NOTIFICATIONS_GETALL_FAILURE',

  CREATE_REQUEST: 'NOTIFICATIONS_CREATE_REQUEST',
  CREATE_SUCCESS: 'NOTIFICATIONS_CREATE_SUCCESS',
  CREATE_FAILURE: 'NOTIFICATIONS_CREATE_FAILURE',

  PUBLISH_REQUEST: 'NOTIFICATIONS_PUBLISH_REQUEST',
  PUBLISH_SUCCESS: 'NOTIFICATIONS_PUBLISH_SUCCESS',
  PUBLISH_FAILURE: 'NOTIFICATIONS_PUBLISH_FAILURE',

  GETBYID_REQUEST: 'NOTIFICATIONS_GETBYID_REQUEST',
  GETBYID_SUCCESS: 'NOTIFICATIONS_GETBYID_SUCCESS',
  GETBYID_FAILURE: 'NOTIFICATIONS_GETBYID_FAILURE',

  UPDATE_REQUEST: 'NOTIFICATIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'NOTIFICATIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'NOTIFICATIONS_UPDATE_FAILURE',
};
